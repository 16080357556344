import React from 'react';
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as Chevron } from './media/arrow.svg';
import { ReactComponent as Chevron2 } from './media/arrow2.svg';

const WorkNav = (props) => {

  const history = useHistory();
  const { category } = useParams();

  // const allCats = ['branding', 'physical', 'digital'];

  const ChangeCategory = (newcat) => {
    history.push("/work/"+newcat);
  }


  return (
    <nav class="work">
      <a className="back" href="/work/">Back</a>
      <a className="study prev" onClick={() => { props.projectNavRef('rev'); }}>
        <span><Chevron /></span>
        <span>prev project</span>
      </a>
      <a className="study next" onClick={() => { props.projectNavRef('fwd'); }}>
        <span><Chevron2 /></span>
        <span>next project</span>
      </a>
    </nav>
  );
}

export default WorkNav;
