import React from 'react';

const Article = {
  date: '2022-05-04',
  title: 'People with PURPOSE',
  // linkedInLink: "https://www.linkedin.com/posts/purposemarketing-australia_purposemarketing-careerswithpurpose-activity-6687519474465275905-DCat",
  image: {
    src: 'media/03 PEOPLE.jpg',
    alt: 'We are PURPOSE'
  },
  content: (<>
    <p>Purpose Marketing is home to a dynamic group of people. Thinkers, experienced creatives talented developers, data analytics obsessives and super storytellers.</p>

    <h5>Genuine & interested</h5>
    <p>PURPOSE people have the ability to listen, because they’re genuinely interested and always thirsty for knowledge. Working collaboratively is second nature, this is how our team create powerful strategies, campaigns and considered marketing solutions that get results. </p>

    <h5>Embrace diversity</h5>
    <p>The team are a mix of local and international professionals with experience of working on brands and businesses throughout Tasmania, Australia and beyond. PURPOSE people work exceptionally hard but also know important it is to have fun whilst working too.</p>

    <h5>Experienced & knowledgeable</h5>
    <p>With combined decades of experience, having worked in international agencies, boutique agencies and local companies, PURPOSE Marketing is totally committed to getting results for its clients. We provide marketing solutions that work and get results. Although we work with clients across a number of categories, we specialise in: Retail, Food & Beverage, Consumer Goods, Hospitality & Travel, Events and Business to Business.</p>

    <h5>Work with purpose</h5>
    <p>Looking for a new role, a different challenge or wish to work with PURPOSE?</p>

    <p>We’re always looking for passionate, experienced and capable people that can make a difference to our clients’ business. Get in touch.</p>
  </>)
};

export default Article;
