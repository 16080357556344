import React from 'react';

import video_loved from "./purpose_marketing_betta_milk_always_loved_tvc.mp4";
import video_together from "./purpose_marketing_betta_milk_always_together_tvc.mp4";

import thumb from "./purpose_marketing_case_studies_betta_milk.webp";
// import logo from "./media/KOLM - Western Tiers Distillery Logo Master.svg";

const Betta = {
  id: 'betta',
  client: 'Betta Milk',
  summary: 'Always Betta Brand Campaign',
  logo: {},
  thumbnail: thumb,
  thumbLarge: true,
  breif_overview: [
    'Betta Milk, renowned for producing quality milk and dairy products. The dairy category is incredibly competitive, the brand needed to re-connect and establish itself in the market. '
  ],
  what_we_did: [
    'PURPOSE Marketing undertook research and uncovered a number of insights and human truths. Our team then set to work on creating a campaign that would appeal and connect with audiences allow the brand to story-tell that resonated with audiences. ',
    'Betta Milk has and always has had, love and respect for the Tasmanian community, farmers and consumers…. and it always will.',
    'The work produced to date, is simply the start of what is to come.'
  ],
  showcase: [
    {
      video: video_loved,
      desc: 'Betta Milk, Always Loved – TVC Creative + Production',
      showControls: true
    },
    {
      video: video_together,
      desc: 'Betta Milk, Always Together – TVC Creative + Production',
      showControls: true
    }
    // {
    //   video: movie2,
    //   desc: 'PURPOSE Marketing created product packaging that allowed Pandani to differentiate themselves from competitors, stand out on shelves and appeal to audiences.'
    // },
    // { image: dolly }
  ],
  results: {
    style: 'double',
    data: [
      [
        <span class="lg">Brand  </span>,
        <span class="md">awareness increased</span>
      ],
      [
        <span class="lg">Sales  </span>,
        <span class="md">Targets are being met </span>
      ]
    ]
  }
};

export default Betta;