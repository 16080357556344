import React from 'react';
import { Link, useLocation, useParams, useHistory, BrowserRouter as Router } from "react-router-dom";

// import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
// import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
// import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

import $ from 'jquery';

import WorkStacker from './WorkStacker';
import MersenneTwister from './mersenne-twister.js';

import { Layout } from './data/';

import WorkNav from './WorkNav';

import PageHeader from 'components/page_header/PageHeader';

// import SliderRevolution from 'components/slider_revolution/SliderRevolution';

import './work.scss';
import './work2.scss';

// import tasFoods from "./media/purpose_works_tasfoods.webp";
// import pyengana from "./media/purpose_works_pyenganadairy.webp";
// import festivale from "./media/purpose_works_festivale.webp";
// import pandani from "./media/purpose_works_pandani.gif";
// import cafebon from "./media/purpose_works_cafebonappetite.webp";
// import tasFoods_mobile from "./media/purpose_works_tasfoods_mobile.webp";
// import pyengana_mobile from "./media/purpose_works_pyenganadairy_mobile.webp";
// import festivale_mobile from "./media/purpose_works_festivale_mobile.webp";
// import pandani_mobile from "./media/purpose_works_pandani_mobile.gif";
// import cafebon_mobile from "./media/purpose_works_cafebonappetite_mobile.webp";


function UpdatePositions() {

  $('#datagrid li').each((ii, elm) => {
    // let ref = $(elm).attr('data-ref');
    // $(elm).css($('#workgrid').find('li[data-ref="'+ref+'"]').position());
    // console.log('+++'+elm.key);
    let targ = $('#workgrid').find('li[data-count="'+ii+'"]');//.position();
    $(elm).attr('data-size', targ.attr('data-size'));
    $(elm).css(targ.position());
    $(elm).addClass('moved');
    $(elm).removeClass('hidden');
  });
}

// function FilterBy(cats) {
//   // window.history.pushState({}, "Title", "/work/?category="+cats);
//   let maxWidth = 0;
//   let count = 0;
//   let active = [];
//   let inActive = [];
//   $('#datagrid li').each((ii,elm) => {
//     if ($(elm).attr('data-categories').includes(cats)) {
//       active.push($(elm));
//     } else {
//       inActive.push($(elm));
//     }
//   });//).length;

//   // $('#datagrid li').each((ii, elm) => {
//   active.forEach(elm => {
//     // console.log($(elm).attr('data-categories').includes(cats));
//     $(elm).addClass('moved');
//     $(elm).removeClass('inActive');
//     // $(elm).addClass('active');
//     let targ = $('#workgrid').find('li[data-count="'+count+'"]');//.position();
//     $(elm).attr('data-size', targ.attr('data-size'));
//     $(elm).css(targ.position());
//     maxWidth = Math.max(maxWidth, targ.position().left + targ.width() + 21 + 21); // 21 = margin
//     count++;
//     // } else {
//     //   $(elm).addClass('hidden');
//     // }
//   });
//   inActive.forEach(elm => {
//     // console.log($(elm).attr('data-categories').includes(cats));
//     $(elm).addClass('moved');
//     // $(elm).removeClass('active');
//     $(elm).addClass('inActive');
//     let targ = $('#workgrid').find('li[data-count="'+count+'"]');//.position();
//     $(elm).attr('data-size', targ.attr('data-size'));
//     $(elm).css(targ.position());
//     maxWidth = Math.max(maxWidth, targ.position().left + targ.width() + 21 + 21); // 21 = margin
//     count++;
//     // } else {
//     //   $(elm).addClass('hidden');
//     // }
//   });
//   $("#datagrid").css("width", maxWidth);
// }



// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const WorkPage = (props) => {


  // const rand = new MersenneTwister(0);

  // let query = useQuery();
  // let params = useParams();
  // let history = useHistory();
  // let { category = 'all' } = useParams();
  // const [ cat, setCat ] = React.useState(Object.is(category, undefined) ? 'all' : category);

  // const [ seed, setSeed ] = React.useState(57906);
  // const changeSeed = (e) => {
  //   setSeed(e.target.value);
  // }


  // const [value, setValue] = React.useState(1);

  //  const handleChange = (event, newValue) => {
  //    setValue(newValue);
  //  };


  // let elms = [];
  // for (let ii=0; ii<20; ii++) {
  //   // elms[ii] = { cats: allCats.map((cat) => (cat === 'all') ? 'all' : (rand.random() > 0.5) ? cat+',' : '')};
  // }

  // CaseStudies.forEach(study => {
  //
  // });


  // let ws = new WorkStacker(CaseStudies, {
  //   seed: seed
  // });
  // let rows = ws.process();


  // let layout = [
  //   [],
  //   [],
  //   []
  // ]

  // const PositionList = rows.map((row, index) => {
  //  let rowItems = row.map((item, i) => (<li data-size={(item.isLarge) ? 'large' : 'small'} data-ref={index+'_'+i} data-count={item.count}></li>));
  //  return (
  //     <ul>
  //       {rowItems}
  //     </ul>
  //   ) ;
  // });



  //   // ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
  //   let workController = new ScrollMagic.Controller();
  //   // build tween
  // var isCrApple = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  //   var workTween = new TimelineMax().timeScale(1)
  //      .to({}, 1, { } )
  //     // .to(".ms", { duration: 0, top: "-400px","padding-bottom": "214px", color: "#105cab"})
  //     // .to(".tg", {duration: 0, opacity: 0})
  //     // .to(".r", {duration: 0, left: '2000px', background:"black"})
  //     // .to(".decoration", {left: '2000px'})

  //     .to(".decoration", { left: (420 / 12.80)+'%' })
  //     .to(".eb", { left: (296/12.80)+'%'}, "<")
  //     .to(".n", { left: (490/12.80)+'%'})
  //     .to(".p", { transform: 'rotateX(0deg)'})
  //     .to(".decoration2", { left: 546/12.80+'%'})
  //     .to("#line01", { height: 'calc(100% + 200px + 20px'+((isCrApple) ? ' + 1px' : '')+')'})
  //     .to("#line02", { width: '4'+((isCrApple) ? '5' : '4')+'%'})
  //     .to("#line03", { height: '88px'})
 
  const Grid = Layout.map((row, index) => (
    <ul class="workgrid">
      {
        row.map((item, i) => (<li data-size={(item.thumbLarge) ? 'large' : 'small'} >
          <a href={'/work/' + item.id}>
            <span class="title">
              <span class="client">{item.client}</span>
              <span class="description">{item.summary}</span>
            </span>
            <div class="image">
              <img src={item.thumbnail} />
              {('thumbMobile' in item) ? <img src={item.thumbMobile} /> : <img src={item.thumbnail} />}
            </div>
          </a>
        </li>))
      }
    </ul>
    )
  );




  // React.useEffect(() => {
  //   UpdatePositions();
  //     // let rowCounts = [0,0,0];
  //     // let largeRatio = 0.6666;
  //     // let rand = new MersenneTwister(1234);



  //     // $('#workgrid li').each((ii, elm) => {
  //     //   // $(this).toggleClass('on');
  //     //   if (rand.random() > largeRatio) {
  //     //     $(elm).toggleClass('large');
  //     //   }
  //     // });

  //   // ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
  //   // let workController = new ScrollMagic.Controller();
  //   // build tween
  //   // var workTween = new TimelineMax().timeScale(1)
  //   //    .to({}, 1, { } )
  //   //   // .to(".ms", { duration: 0, top: "-400px","padding-bottom": "214px", color: "#105cab"})
  //   //   // .to(".tg", {duration: 0, opacity: 0})
  //   //   // .to(".r", {duration: 0, left: '2000px', background:"black"})
  //   //   // .to(".decoration", {left: '2000px'})
  //   //
  //   //   .to(".decoration", { left: (420 / 12.80)+'%' })
  //   //   .to(".eb", { left: (296/12.80)+'%'}, "<")
  //   //   .to(".n", { left: (490/12.80)+'%'})
  //   //   .to(".p", { transform: 'rotateX(0deg)'})
  //   //   .to(".decoration2", { left: 546/12.80+'%'})
  //   //   .to("#line01", { height: 'calc(100% + 200px + 20px)'})
  //   //   .to("#line02", { width: '44%'})
  //   //   .to("#line03", { height: '88px'})


  //     // var blockTween = new TimelineMax().call(function() {
  //     //
  //     //   $('#workTrigger').addClass("show");
  //     // }, null, null, 2);
  //     // let workScene = new ScrollMagic.Scene({triggerElement: "#workTrigger", duration: 1200,
  //     //  offset: 0})
  //     //  .setTween(blockTween) // the tween durtion can be omitted and defaults to 1
  //     //  .addIndicators()
  //     //  .addTo(workController);

  // },[]);


  return (<>



  <div class="workWrapper">
   

      {Grid}




    </div>
    </>
  );

}

export default WorkPage;
