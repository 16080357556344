import React from 'react';

import video_casestudy from "./purpose_marketing_hurricanes_digital paid_media.mp4";
import video_billboard from "./purpose_marketing_hurricanes_ooh.mp4";
import video_tasteam from "./purpose_marketing_hurricanes_tasmanias_team.mp4";
import video_social from "./purpose_marketing_hurricanes_social_media.mp4";

import thumb from "./purpose_marketing_case_studies_hobart_hurricanes.webp";
// import logo from "./media/KOLM - Western Tiers Distillery Logo Master.svg";

const Hurricanes = {
  id: 'hurricanes',
  client: 'Hobart Hurricanes',
  summary: 'Brand Evolution + Campaign Execution',
  logo: {},
  thumbnail: thumb,
  thumbLarge: true,
  breif_overview: [
    'The Hobart Hurricanes is a renowned team in the Big Bash League, there was a need to revitalise the look/feel of the brand and give it a new lease of life and importantly, the brand and create needed to appeal, sell tickets and memberships. '
  ],
  what_we_did: [
    'Creating a robust integrated strategy, fuelled by insight, together with a true understanding of the audience behaviour, we developed a creative territory that was ownable, fresh and had the power to excite and connect with audiences.', 
    'Marketing material and other brand assets were designed and rolled out across various channels as part of the overall marketing strategy at specific times to maximise opportunity.'
  ],
  showcase: [
    {
      video: video_tasteam,
      desc: 'Brand Logo + Lock-up'
    },
    {
      video: video_casestudy,
      desc: 'Digital Paid Media'
    },
    {
      video: video_billboard,
      desc: 'OOH – Digital Signage'
    },
    
    {
      video: video_social,
      desc: 'Social Media Activity'
    }
    // {
    //   video: movie2,
    //   desc: 'PURPOSE Marketing created product packaging that allowed Pandani to differentiate themselves from competitors, stand out on shelves and appeal to audiences.'
    // },
    // { image: dolly }
  ],
  results: {
    style: 'double',
    data: [
      [
        <span class="lg">Sales</span>,
        <span class="md"> For membership  </span>,
        <span class="sm alt">Increased + target hit</span>
      ],
      [
        <span class="lg">Ticket</span>,
        <span class="md">Sales for matches </span >,
        <span class="sm alt">Significant increase compared to previous year</span>

      ]
    ]
  }
};

export default Hurricanes;