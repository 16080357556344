import React from 'react';

import { useParams, Link } from "react-router-dom";

import moment from 'moment';
import $ from 'jquery';

import Articles from './data';

import './insightDetail.scss';

import { ReactComponent as LinkedInIcon } from 'global/media/linkedin.svg';

const InsightDetailPage = (props) => {


  let { article } = useParams();
  const ArticleDetail = Articles.filter((art) => art.date === article)[0];
  
  // get the previous and next article data to be used as link
  const ArticleIndex = Articles.indexOf(ArticleDetail);
  const prevArticleLink = Articles[ArticleIndex - 1]?.date;
  const nextArticleLink = Articles[ArticleIndex + 1]?.date;


  // React.useEffect(() => {
  //   // $(document).ready(function() {
  //   //      // $("head").append("<meta property='og:title' content='"+ArticleDetail.title+"'/>");
  //   //      // $("head").append("<meta property='og:image' content='"+require('./data/'+ArticleDetail.image.src)+"'/>");
  //   //      // // $("head").append("<meta property='og:description' content='Description that will show in the preview'/>");
  //   //      // $("head").append("<meta property='og:url' content='https://purposemarketing.com.au/news/"+ArticleDetail.date+"' />");
  //   //   });
  // }, []);


  return (
    <section id="insightDetail">
      <article>
        {/* <a className="back" href="#" onClick={() => window.history.back()}>back</a> */}

        <img src={require('./data/' + ArticleDetail.image.src)} />
        <span className="date">{moment(ArticleDetail.date).format("dddd Do MMMM YYYY")}</span>
        <h4>{ArticleDetail.title}</h4>
        {ArticleDetail.content}
        {prevArticleLink ? <Link className="link prev" to={"/insights/" + prevArticleLink}>previous</Link> : ''}
        {nextArticleLink ? <Link className="link next" to={"/insights/" + nextArticleLink}>next</Link> : ''}
        {ArticleDetail.linkedInLink && (<div className="share"><a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + ArticleDetail.linkedInLink} target="_blank">share<LinkedInIcon /></a></div>)}
      </article>
    </section>
  );

}

export default InsightDetailPage;
