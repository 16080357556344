import React from 'react';

//
// import {ReactComponent  as Logo} from './media/purpose_logo.svg';

 import './09_footer.scss';
// import pp from '../../media/Privacy Policy.pdf';


const Footer =(props) => {

  const [atBottom, setAtBottom] = React.useState(false);
  // React.useMemo(() => svgReplace, []);

/*
(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';}(jQuery));var $mcj = jQuery.noConflict(true);
*/  window.onscroll = (e) => {
    if ((window.innerHeight + window.scrollY + 100) >= document.body.offsetHeight) {
      if (!atBottom) {
        setAtBottom(true);
      }
    } else {
      if (atBottom) {
        setAtBottom(false);
      }
    }
  };


  return(<>



    <footer id="footer">
      <div>
        <div><span>&copy; Copyright 2021 </span> <span>PURPOSE Marketing.</span> <span> All rights reserved.</span><br /><span><a href="/Privacy Policy.pdf" target="_blank">privacy policy</a></span></div>
        <div className={'top'+((atBottom) ? ' show' : '')}><a href="#_top">Return to top</a></div>
        <div className="tagline">Every business needs <span>PURPOSE</span></div>
      </div>
    </footer>

    <svg id="svg-filters">
      <defs>
        <filter id="inner-glow">
          <feFlood floodColor="rgba(0,0,0,0.2)"/>
          <feComposite in2="SourceAlpha" operator="out"/>
          <feGaussianBlur stdDeviation="2" result="blur"/>
          <feOffset dx="1" dy="2" />
          <feComposite operator="atop" in2="SourceGraphic"/>
        </filter>

      <filter id="glow">
        <fegaussianblur className="blur" result="coloredBlur" stdDeviation="4"></fegaussianblur>
        <femerge>
          <femergenode in="coloredBlur"></femergenode>
          <femergenode in="coloredBlur"></femergenode>
          <femergenode in="coloredBlur"></femergenode>
          <femergenode in="SourceGraphic"></femergenode>
        </femerge>
      </filter>

      </defs>
    </svg>

  </>);
}

export default Footer;
