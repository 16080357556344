import React from 'react';

import video_website from "./purpose_marketing_indulge_website_experience.mp4";
import video_social from "./purpose_marketing_indulge_social_media.mp4";
import image_logo from "./purpose_marketing_indulge_logo.webp";

import thumb from "./purpose_marketing_case_studies_indulge_2.webp";
import thumbmobile from "./purpose_marketing_case_studies_indulge_mobile.webp";
// import logo from "./media/KOLM - Western Tiers Distillery Logo Master.svg";

const Indulge = {
  id: 'indulge',
  client: 'Indulge',
  summary: 'Brand Ideation + Campaign',
  logo: {},
  thumbnail: thumb,
  thumbMobile: thumbmobile,
  thumbLarge: false,
  breif_overview: [
    'When it was announced that a new and unique event was to take place in UTAS Stadium 2021, this was a challenge that PURPOSE Marketing readily accepted. '
  ],
  what_we_did: [
    'With a reluctance for people to venture outside or attend large events due to Covid-19, we needed to think differently as people weren’t venturing far from home and were reluctant to attend events. ',
    'A speed to market strategy was needed, creating a desire and need to attend the event was part of the strategy. As was the decision to make all materials bold and bright, with clear and concise messaging. ',
    'We developed a fitting name for the event: Indulge. We then developed a logo and other marketing assets that allowed the event to stand out and to be considered state-wide across a number of channels. '
  ],
  showcase: [
    {
      video: video_website,
      desc: 'Website Experience'
    },
    {
      video: video_social,
      desc: 'Social Media Activity'
    },
    {
      image: image_logo,
      desc: 'Brand Logo'
    }
    // {
    //   video: movie2,
    //   desc: 'PURPOSE Marketing created product packaging that allowed Pandani to differentiate themselves from competitors, stand out on shelves and appeal to audiences.'
    // },
    // { image: dolly }
  ],
  results: {
    style: 'single',
    data: [
      [
        <span class="lg">Sold out </span>,
        <span class="md">Sessions over 3 days  </span>,
        <span class="sm alt">All tickets sold online </span>,
        <span class="sm alt">prior to gates opening </span>
      ]
    ]
  }
};

export default Indulge;