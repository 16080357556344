import React from 'react';

const Article = {
  date: '2022-05-11',
  title: 'Social Media Matters',
  linkedInLink: 'https://www.linkedin.com/posts/purposemarketing-australia_social-media-matters-activity-6929983091788713984-NTSP',
  image: {
    src: 'media/07 SOCIAL MEDIA MATTERS[1].jpg',
    alt: 'Social Media Matter'
  },
  content: (<>
    <p>There’s so much more to social media than just posting content and what you post matters more than you probably think. It’s only when you place a considered strategy behind your social media efforts that you’ll start to understand its importance and maximise results. </p>

    <p>Below are just a few tips on how to create a strong social media presence that reflects your brand.</p>

    <h5>It’s Not Personal – It’s Business </h5>
    <p>Your personal social profiles express who you are as a person and insight can be gained by the activity you take. Your business profile on social media expresses the purpose and personality of your brand. Every move you make on social media reflects your business, so making sure that you’re posting content that’s relevant and serves a purpose is important.</p>

    <h5>Consider Content</h5>
    <p>Producing posts that have no relevance, don’t reflect your brand or have some connection to your business offering isn’t the best way to approach social media. Each post that goes live needs to have a purpose behind it, so that the posts help to convey a particular message and/or contribute to reaching a specific goal for the business. </p>

    <h5>Focus on Conversation</h5>
    <p>Social media is one of the easiest ways for businesses to have a two-way conversation directly with existing and potential customers. However, time and time again, we see businesses talking at their audiences as opposed to opening up an opportunity to have a conversation. Gain further insight and create opportunities for conversation by asking your audiences for their opinion, respond to comments and engage. </p>

    <h5>Use the Right Channels</h5>
    <p>Your business doesn’t need to necessarily appear on all social media platforms. Spend your time wisely and work smart. Use data and insight to figure out where your audiences are and tailor your messages accordingly. </p>

    <p>If you haven’t been getting the results from your social media efforts, need assistance and wish to discuss your business needs then we’d love to hear from you. </p>

  </>)
};

export default Article;
