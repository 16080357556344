import React from 'react';

const Article = {
  date: '2023-08-10',
  title: 'The Value of Content that Connects',
  image: {
    src: 'media/content that connects linkedin.png',
    alt: 'The Value of Content that Connects'
  },
  content: (<>
    <p>We’ve all experienced this situation more than a few times: We walk into a retail store, we need help and it’s impossible to find the product we’re looking for, or it becomes difficult to find a sales assistant to help, or we find an assistant, but they have little or no knowledge or can’t help at all. </p>
    <p>This lack of customer service is becoming more and more frustrating for shoppers, often leading to the shopper leaving the store and no longer wanting to shop with that specific store/brand again. People also have the same experiences in the online world too. </p>
    <p>Surprisingly, some retailers won’t even realise this is happening and those retailers that are aware but choose not to act is a mistake and could cost them dearly. </p>

    <h5>Physical Store Experience</h5>
    <p>In a retail store, employees are the face of your brand. Retailers go to great lengths, spending time and money on getting the right people onboard. People with the skill, experience and personality needed to best represent the brand and sell.</p>
    <p>How products are visually merchandised, positioned and located on the floor is important too. After all, retailers want to sell and how products are showcased is imperative. The products need to look their best and great effort is taken to ensure that they can be seen and found with little or no effort.</p>
    <p>Getting it right is crucial, after all, it can be the difference between making a sale and not. </p>

    <h5>Online Store Experience</h5>
    <p>Some brands work extensively on their physical experience, investing $000’s, but to their detriment tend to rush or not really consider their online experience as much as they do the physical, so their online experience can often be lacking which in today’s world can be a costly mistake to make. </p>
    <p>When a potential shopper visits a site, most expect and should be able to find a store that mirrors the physical store experience: use of the same language, copy that’s relevant and informative, imagery that showcases the product to its best and navigating should be as easy as if they were in the physical store.  </p>
    <p>To the detriment of some retailers, this isn’t the case and is likely to be costing them each day as sales opportunities are being lost. </p>
    <p>Retailers, ask yourselves this: Is your online store providing an experience that truly reflects your physical experience? If not, take action, don’t just put it on the “I’ll do that soon list” as it could be losing you customers, data and a substantial amount of money in sales. </p>
    <p>PURPOSE Marketing specialises in retail. In fact, we love it, we live and breathe it every day and our knowledge and experience is unrivalled in Tasmania. If you need help or wish to know more, then please get in touch.  </p>

  </>)
};

export default Article;
