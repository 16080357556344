import React, { Suspense } from 'react';

import './App.scss';


import ScrollToTop from './ScrollToTop';

import withTracker from './withTracker';

import Header from 'components/header/Header'
import Footer from 'components/footer/Footer'
// import MessagePanel from 'components/message_panel/MessagePanel'

import HomePage from './pages/home/HomePage';
import ServicesPage from 'pages/services/ServicesPage';
import InsightsPage from 'pages/insights/InsightsPage';
import InsightDetailPage from 'pages/insights/InsightDetailPage';
import WorkPage from 'pages/work/WorkPage';
import WorkDetailPage from 'pages/work/WorkDetailPage';
import CareersPage from './pages/careers/CareersPage';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";



import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

const MessagePanel = React.lazy(() => import('components/message_panel/MessagePanel'));

const BG = () => {
  return (<div id="bg">
    <div />
    <div />
    <div />

  </div> )
}


function App() {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
  return (
    <Router>

      <ScrollToTop>
        <Header />
        <main>
          <Switch>
            <Route path="/services" component={withTracker(ServicesPage)} />
            <Route path="/careers" component={withTracker(CareersPage)} />
            <Route path="/news/:article" component={withTracker(InsightDetailPage)} />
            <Route path="/news" component={withTracker(InsightsPage)} />
            <Route path="/insights/:article" component={withTracker(InsightDetailPage)} />
            <Route path="/insights" component={withTracker(InsightsPage)} />
            <Route path="/work/:study" component={withTracker(WorkDetailPage)} />
            <Route path="/work" component={withTracker(WorkPage)} />
            <Route path="/" component={withTracker(HomePage)} />
          </Switch>
          <Suspense fallback={<div>Loading...</div>}>
            <MessagePanel />
          </Suspense>
       </main>
       <Footer />
       <BG />
     </ScrollToTop>
   </Router>
  );
}

export default App;
