import React from 'react';

import './03_about.scss';
import './05_experience.scss';
import './homepage.scss';




import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";


import {ReactComponent  as B2BIcon} from './media/Specialist_Icon_new_b2b_white.svg';
import {ReactComponent  as ConsumerIcon} from './media/Specialist_Icon_new_consumer_white.svg';
import {ReactComponent  as EventsIcon} from './media/Specialist_Icon_new_events_white.svg';
import {ReactComponent  as FBIcon} from './media/Specialist_Icon_new_f&b_white.svg';
import {ReactComponent  as RetailIcon} from './media/Specialist_Icon_new_retail2_white.svg';
import {ReactComponent  as TravelIcon } from './media/Specialist_Icon_new_t&h_white.svg';
import Video from './media/Marketing agency purpose marketing video.mp4';

import PageHeader from 'components/page_header/PageHeader';





const HomePage = (props) => {

  React.useEffect(() => {
    // ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
  //
    let homeController = new ScrollMagic.Controller();
    var isCrApple = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

    // build tween
    			var homeTween = new TimelineMax().timeScale(1)
    				.to({}, 1, { } )
            .to(".decoration2", { left: (420 / 12.80)+'%' })
    				.to(".ms", { top: (120/4.00)+'%' })
    				// .to(".ms", {'padding-bottom': 30/12.80+"%" })
    				.to(".tg", {opacity: 1 })
    				.to(".r", { left: (568 / 12.80)+'%' })
    				.to(".decoration", { left: (568 / 12.80)+'%' })
            .to("#line01", { height: 'calc(100% + 200px + 20px'+((isCrApple) ? ' + 1px' : '')+')'})
            .to("#line02", { width: '4'+((isCrApple) ? '5' : '4')+'%'})
            .to("#line03", { height: '38px'})
    				// .to(".ms", { color: '#fff'} )
    				// .to(".tg", { color: '#fff'} )

            .to("#line03", { height: '88px'})
    //
    // let homeScene = new ScrollMagic.Scene(
    //     {triggerElement: "#magicWrap",
    //     duration: 0,
    //     triggerHook: 0})
    //         // .setPin("#pageheader")
    //         .setTween(homeTween) // the tween durtion can be omitted and defaults to 1
    //         .addTo(controller);

		var aboutTween = new TimelineMax().timeScale(4)
    .to("#about .container", {'column-gap':'10px'})
    .to("#about .container .col-1", {'margin-left': '0px'}, "<");

    let aboutScene = new ScrollMagic.Scene({triggerElement: "#magicWrap2", duration: 400,
    triggerHook: 0, offset: -800})
            // .setPin("#about")
             .setTween(aboutTween) // the tween durtion can be omitted and defaults to 1
            .addTo(homeController);

    var experinceTween = new TimelineMax().timeScale(4)
            .to('.gapLines .line01', {  width: '2000px'})
            .to('.gapLines .line02', {  height: '170px'})
            .to('#experience .specialists', {'margin-left': 0, duration: 1})
            .to('#experience .copy', {  'transform': 'rotateX(0deg)'})
            // .to('#experience h3', {'margin-left': 0})
            .to('#experience .experience p', {'opacity': 1})
            .to('#experience .line01', {width: '1000px'})
            .to('#experience .line02', {height: 'calc(100% + 20px)'})
            .to('#experience .line03', {width: '4000px'})
/*
    var experinceTween = new TimelineMax().timeScale(4)
            .to('.gapLines .line01', {  width: '2000px'})
            .to('.gapLines .line02', {  height: '170px'})
            .to('#experience .specialists', {'margin-left': 0, duration: 3})
            .to('#experience .copy', {  'transform': 'rotateX(0deg)'})
            .to('#experience h3', {'margin-left': 0})
            .to('#experience .experience p', {'opacity': 1})
            .to('#experience .line01', {width: '1000px'})
            .to('#experience .line02', {height: 'calc(100% + 20px)'})
            .to('#experience .line03', {width: '4000px'})


    let experienceScene = new ScrollMagic.Scene({triggerElement: "#magicWrap3", duration: 1200,
    triggerHook: 0, offset: -800})

*/

    let experienceScene = new ScrollMagic.Scene({triggerElement: "#magicWrap3", duration: 1200,
    triggerHook: 0, offset: -800})

            .setTween(experinceTween) // the tween durtion can be omitted and defaults to 1
            .addTo(homeController);
  },[]);

  return (
    <>



    <PageHeader>
        <div className="homepage-one">
          <div className="decoration2" />
          <span className="ms" id="ms">Marketing solutions</span>
          <span className="tg">that get</span>
          <span className="r">results</span>
          <span className="decoration" />
        </div>
    </PageHeader>



    <div id="magicWrap2" >

        </div>

    <section id="about">
      <div className="container">
        <div className="col-1" >
          <h3 className="journey">Who<span> we are</span></h3>
            <p>PURPOSE Marketing is a digital and creative agency that provides innovative creative, digital and marketing solutions that get results for our clients.  </p>
            <p>Everything we do is driven by insights and data to inform our strategies, creative, digital and marketing solutions. </p>
            <p>We think differently to others and create considered, integrated strategies, campaigns, websites and marketing solutions that not only look spectacular, they achieve desired results for our clients.</p>
        </div>
        {/* <div className="col-2">
          <h3 className="journey">What <span>makes us different</span></h3>
          <p>PURPOSE Marketing <strong>thinks differently</strong> and loves to <strong>create considered, integrated marketing solutions</strong> that not only look spectacular, they achieve results.</p>
          <p>Creating <strong>work that excites and engages</strong> audiences, we’re able to work with <strong>speed and efficiency</strong>. Our team understands, embraces and actively works within traditional media, digital and social channels.</p>
        </div> */}
      </div>
      <div className="gapLines">
        <div className="line01" />
        <div className="line02" />
      </div>
    </section>

    <div id="magicWrap3" />

    <section id="experience">
      <div className="anchor" id="_experience"/>
      <div className="container">

          <div className="media">
            <span className="copy">
              <p>PURPOSE Marketing works collaboratively with clients of all sizes across a number of different industries and armed with knowledge and experience we’re able to make a difference.</p>
            </span>
              <div className="images">
                <video autoPlay playsInline muted loop>
                  <source src={Video} type="video/mp4" />
                </video>

              </div>
          </div>

          <div className="experience">
            <h5>Our team is diverse, a mix of local and international talent who are experienced, knowledgeable and incredibly passionate. Working collaboratively as a team helps us to get results for our clients across Tasmania. </h5>

            <h3 className="journey">Why <span>work with us</span></h3>

            <p>PURPOSE Marketing is home to a fun group of like-minded professionals that work hard and love what we do. All of the team are dedicated to make a real difference to our clients’ business across a number of core disciplines: strategy, creative &amp; digital.</p>
            <p>As a results driven agency, achieving success for our clients is vital. Every member of our team brings a unique, fresh perspective and insight to every project, user journey and campaign we work upon. </p>

          </div>



          <div className="specialists">
          <div className="lines">
            <div className="line01" />
            <div className="line02" />
            <div className="line03" />
          </div>
            <h6>Specialist areas:</h6>
            <ul>
              <li>
                <RetailIcon />
                <span>Retail</span>
              </li>
              <li>
                <FBIcon />
                <span>Food + <br />Beverage</span>
              </li>
              <li>
                <TravelIcon />
                <span>Travel + <br />Hospitality</span>
              </li>
              <li>
                <ConsumerIcon />
                <span>FMCG</span>
              </li>
              <li>
                <B2BIcon />
                <span>Business <br />2 Business</span>
              </li>
              <li>
                <EventsIcon />
                <span>Events</span>
              </li>
            </ul>
          </div>


        </div>
    </section>
    </>
  );

}

export default HomePage;
