import React from 'react';

import video_photoshoot from "./purpose_marketing_igloo_cold_storage_photoshoot.mp4";
import video_website from "./purpose_marketing_igloo_cold_storage_website_experience.mp4";

import thumb from "./purpose_marketing_case_studies_iglooe_cold_storage.webp";
import thumbmobile from "./purpose_marketing_case_studies_iglooe_cold_storage-mobile.webp";
// import logo from "./media/KOLM - Western Tiers Distillery Logo Master.svg";

const Igloo = {
  id: 'igloo',
  client: 'Igloo',
  summary: 'Brand creation + online experience ',
  logo: {},
  thumbnail: thumb,
  thumbMobile: thumbmobile,
  thumbLarge: false,
  breif_overview: [
    'As a new company in Tasmania, the business needed to appeal to specific audiences and complete with larger players in the market. The client had specific goals and targets in place that needed to be hit within a short space of time. '
  ],
  what_we_did: [
    'We took the logo and created a complete identity that would allow the brand to live, thrive and compete. Creating visuals and graphic elements needed for the initial phase of growth that stood out and placed the brand into the immediate consideration set of audiences. ',
    'The website experience was simple, informative and easy to navigate, built with specific audiences in mind and that would allow enquiries / quotes to come through with ease. Implemented with an SEO and digital strategy the new brand was found by many, in a very short space of time. '
  ],
  showcase: [
    {
      video: video_photoshoot,
      desc: 'Vehicle Livery'
    },
    {
      video: video_website,
      desc: 'Website Experience'
    }
    // {
    //   video: movie2,
    //   desc: 'PURPOSE Marketing created product packaging that allowed Pandani to differentiate themselves from competitors, stand out on shelves and appeal to audiences.'
    // },
    // { image: dolly }
  ],
  results: {
    style: 'double',
    data: [
        [
          <span class="lg">Sales </span>,
          <span class="md">Targets achieved </span>,
          <span class="sm alt">1 year before expected.</span>
        ],
        [
          <span class="lg">Online</span>,
          <span class="md">enquiries continue </span>,
          <span class="sm alt">to exceed expectation</span>
      ]
    ]
  }
};

export default Igloo;