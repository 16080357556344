import React from 'react';

import { Link } from "react-router-dom";

import moment from 'moment';

import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

import Articles from './data';

import PageHeader from 'components/page_header/PageHeader';

import './insights.scss';


const InsightsPage = (props) => {

  const ArticleList = Articles.map((article, index) => {
   return (
      <li key={'art_'+index}>
        <Link to={"/insights/"+article.date}>
          <img src={require('./data/'+article.image.src)} alt={article.image.alt} />
          <span className="date">{moment(article.date).format("dddd Do MMMM YYYY")}</span>
          <h5>{article.title}</h5>
          <span className="read_more">read more</span>
        </Link>
      </li>
    ) ;

  });


  React.useEffect(() => {
    // ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
  var isCrApple = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    // build tween
    			new TimelineMax().timeScale(1)
    				 .to({}, 1, { } )
    				// .to(".ms", { duration: 0, top: "-400px","padding-bottom": "214px", color: "#105cab"})
    				// .to(".tg", {duration: 0, opacity: 0})
    				// .to(".r", {duration: 0, left: '2000px', background:"black"})
    				// .to(".decoration", {left: '2000px'})
            .to(".decoration2", { left: (420 / 12.80)+'%' })
    				.to(".myb", { top: (100 / 4.00)+'%' })
    				// .to(".myb", {'padding-bottom': 20/12.80+"%" })
    				.to(".fw", {opacity: 1 })
    				.to(".p", { left: (568 / 12.80)+'%' })
    				.to(".decoration", { left: (568 / 12.80)+'%' })
    				// .to(".myb", { color: '#fff'} )
    				// .to(".fw", { color: '#fff'} )
      			.to(".decoration", { left: 568/12.80+'%' })
    				// .to(".p", { background: '#105cab'} );
            .to("#line01", { height: 'calc(100% + 200px + 20px'+((isCrApple) ? ' + 1px' : '')+')'})
            .to("#line02", { width: '4'+((isCrApple) ? '5' : '4')+'%'})
            .to("#line03", { height: '38px'})


    // let scene = new ScrollMagic.Scene(
    //     {triggerElement: "#magicWrap",
    //     duration: 0,
    //     triggerHook: 0})
    //         // .setPin("#pageheader")
    //         .setTween(tween) // the tween durtion can be omitted and defaults to 1
    //         .addTo(controller);
              },[]);
  return (
<>
    <PageHeader>
      <div className="news-one">
        <div className="decoration2" />
        <span className="myb">move your business</span>
        <span className="fw">forward with</span>
        <span className="p">PURPOSE</span>
        <div className="decoration" />
      </div>
    </PageHeader>

    <section id="insights">
      <span className="intro">The PURPOSE Marketing team are passionate and always thirsty for knowledge.<br />
        Below are a few of our latest thoughts, articles and opinion pieces. <br /><br />
        Follow PURPOSE Marketing on <a href="https://www.linkedin.com/company/purposemarketing-australia/?viewAsMember=true">LinkedIn</a> and <a href="https://www.facebook.com/purposemarketingtas" target="_blank" title="Facebook">Facebook</a> to keep up to date.</span>

      <ol>
        { ArticleList }
      </ol>
    </section>
</>
  );

}

export default InsightsPage;
