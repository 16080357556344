import React from 'react';

const Article = {
  date: '2022-05-09',
  title: 'Retail experience is more important than ever',
  // linkedInLink: "https://www.linkedin.com/posts/purposemarketing-australia_purposemarketing-retailmarketing-activity-6688600596636942336-n52a",
  image: {
    src: 'media/04 RETAIL.jpg',
    alt: 'Retail experience is more important than ever'
  },
  content: (<>
    <p>As COVID-19 restrictions began to ease and shoppers are making their way back into retail stores, the behaviour of many consumers is likely to not be the same as it was before the Pandemic.Overall, the attitude towards shopping has and is changing.There’s a need for retailers to deliver a consistent shopping experience if they’re to further appeal and increase opportunity for sales. </p>

    <h5>Be Consistent &amp; Personalise</h5>

    <p>Consumers in Australia are craving normality and stability. Especially now, the way in which retailers behave and the experience they provide is more important than ever. Most people when shopping look for a consistent physical or digital shopping experience. A considered store layout, personalised service, the ability to find products with ease and for messaging to be clear and relevant are just some of the “wants” of consumers. </p>

    <h5>Human Interaction</h5>

    <p>During self-isolation, most people have re-evaluated the importance of human interaction. As consumers start navigating their way out of quarantine, there’s a need for retailers to focus on both digital and physical to create more meaningful connections. By connecting the digital and physical experiences as opposed to treating them differently, retailers can create deeper connections with shoppers, coupled with the opportunity to increase both basket size and conversion rates.</p>

    <p>PURPOSE Marketing has vast retail knowledge and experience, we understand the pressures and challenges that many retailers currently face. </p>

    <p>Should you need assistance or would like to discuss your business needs, get in touch. </p>
  </>)
};

export default Article;
