import React from 'react';

const PageHeader = (props) => {
  return(
    <>
    <div id="magicWrap" />
      <div id="_top" className="anchor" />
      <section id="pageheader">
        <div>
          <div>
            <div className="lineHolder">
              <div id="line01" />
              <div id="line02" />
              <div id="line03" />
            </div>
            {props.children}
          </div>
        </div>
      </section>
    </>
  );
}

export default PageHeader;
