import React from 'react';

import video_website from "./purpose_marketing_western_tiers_distillery_online_store.mp4"; 
import video_gin from "./purpose_marketing_western_tiers_distillery_packaging_gin.mp4"; 
import video_pottin from "./purpose_marketing_western_tiers_distillery_packaging_poitin.mp4"; 
import video_vodka from "./purpose_marketing_western_tiers_distillery_packaging_vodka.mp4"; 
import video_photoshoot from "./purpose_marketing_western_tiers_distillery_photoshoot.mp4"; 
import video_social from "./purpose_marketing_western_tiers_distillery_social_media.mp4"; 
import dolly from "./purpose_marketing_case_studies_western_tiers_distillery.webp";
import logo from "./KOLM - Western Tiers Distillery Logo Master.svg";

const WesternTiersDistillery = {
  id: 'wtd',
  client: 'Western Tiers Distillery',
  summary: 'Digital + Physical brand experience',
  logo: logo,
  thumbnail: dolly,
  thumbLarge: true,
  breif_overview: [
    'As Tasmania’s newest distillery entering a cluttered and very competitive landscape, Western Tiers Distillery needed to launch successfully and hit targets for visitation and sales.'
  ],
  what_we_did: [
    'As specialists in Luxury Brands, Food & Beverage our team were armed with national / global knowledge and knew that a robust, solid integrated marketing strategy, coupled with the need to think differently to succeed was needed.',
    'Leveraging off the brand name and location, we created a unique creative look and feel for the brand, developed product names and packaging that not only looks beautiful but would appeal and stand out on shelf in retail and on premise. A variety of marketing assets were produced for key channels targeting both consumer / trade audiences.'
  ],
  showcase: [
    {
      video: video_website,
      desc: 'Online Store'
    },
    {
      video: video_gin,
      desc: 'Packaging'
    },
    {
      video: video_pottin,
      desc: 'Packaging'
    },
    {
      video: video_vodka,
      desc: 'Packaging'
    },
    {
      video: video_photoshoot,
      desc: 'Photography + Imagery Creation'
    },
    {
      video: video_social,
      desc: 'Social Media Activity'
    }
    // {
    //   video: movie2,
    //   desc: 'PURPOSE Marketing created product packaging that allowed Pandani to differentiate themselves from competitors, stand out on shelves and appeal to audiences.'
    // },
    // { image: dolly }
  ],
  results: {
    style: 'double',
    data: [
      [
        <span class="lg"> Sales</span>, 
        <span class="md"># visitors visitors</span>,
        <span class="sm alt">Continue to increase month on month</span>
      ],
      [
        <span class="lg">Traffic </span>,
        <span class="md">is high + RESULTS,</span >,
        <span class="sm alt">are being achieved</span>

      ]
    ]
  }
};

export default WesternTiersDistillery;