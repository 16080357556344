import React from 'react';

import video_pulled from "./purpose_marketing_pandani_smallgoods_packaging_freshly_cut_pulled_range.mp4"; 
import video_sausage from "./purpose_marketing_pandani_smallgoods_packaging_sausage.mp4"; 
import video_website from "./purpose_marketing_pandani_smallgoods_website_experience.mp4"; 
import thumbnail from "./purpose_marketing_case_studies_pandani.webp";
import logo from "./Black Logo — No Background.svg";

const Pandani = {
  id: 'pandani',
  client: 'Pandani',
  summary: 'Packaging + Product Launch ',
  logo: logo,
  thumbnail: thumbnail,
  thumbLarge: true,
  breif_overview: [
    'Pandani is a family-owned business renowned for producing a large range of quality smallgoods. PURPOSE Marketing was briefed to launch two new product ranges into the national Australian market, targeting consumers and stockists.'
  ],
  what_we_did: [
    'Knowing the meat and smallgoods category exceptionally well, a brand overhaul was required. The logo was refreshed, a brand strapline created and all brand assets were re-designed. The existing website user experience was also changed to become relevant and appeal to audiences to further assist success.',
    'PURPOSE Marketing created product packaging that allowed the brand to differentiate products from competitor brand that stood out on shelf.  Imagery used created desire and packaging content was bold and aspirational, appealing to both consumers and retailers alike.'
  ],
  showcase: [
    {
      video: video_sausage,
      desc: 'Packaging'
    },
    {
      video: video_pulled,
      desc: 'Packaging'
    },
    {
      video: video_website,
      desc: 'Website Experience'
    }
    // {
    //   video: movie2,
    //   desc: 'PURPOSE Marketing created product packaging that allowed Pandani to differentiate themselves from competitors, stand out on shelves and appeal to audiences.'
    // },
    // { image: dolly }
  ],
  results: {
    style: 'double',
    data: [
      [
        <span class="lg">+28%</span>, 
        <span class="md">over + above yearly </span>,
        <span class="sm alt">sales targets in 6 months </span>,
        <span class="sm alt">Freshly cut / pulled range </span>
      ],
      [
        <span class="lg">+20%</span>,
        <span class="md">over + above yearly </span>,
        <span class="sm alt">sales targets in 7 months </span>,
        <span class="sm alt">sausage range </span>
      ]
    ]
  }
};

export default Pandani;