import React from 'react';

import video_website from "./purpose_marketing_bass_disability_website_experience.mp4";
import image_logo from "./purpose_marketing_bass_disability_logo.webp";

import thumb from "./purpose_marketing_case_studies_bass_disability.webp";
import thumbmobile from "./purpose_marketing_case_studies_bass_disability-mobile.webp";
// import logo from "./media/KOLM - Western Tiers Distillery Logo Master.svg";

const BDSS = {
  id: 'bdss',
  client: 'Bass Disability',
  summary: 'Brand Creation + online experience',
  logo: {},
  thumbnail: thumb,
  thumbMobile: thumbmobile,
  thumbLarge: false,
  breif_overview: [
    'The disability and support service market is saturated with businesses offering a same/similar service, with many of the larger competitors dominating. PURPOSE Marketing were asked to assist Bass Disability to launch and enter the market. '
  ],
  what_we_did: [
    'Armed with knowledge and experience in the disability sector, our team created a logo, strapline and identity for the brand. Differentiating the brand from competitors, a number of brand and marketing assets were created to excite and resonate with audiences. ',
    'The website experience needed to be simple, easy to navigate and update, implemented with other specific marketing materials used to promote the brand and create awareness. '
  ],
  showcase: [
    { 
      image: image_logo,
      desc: 'Brand Logo'
    },
    {
      video: video_website,
      desc: 'Website Experience'
    }
  ],
  results: {
    style: 'single',
    data: [
      [
        <span class="lg">+ 190% </span>,
        <span class="md">above yearly targets </span>,
        <span class="sm alt">In just over over 7 months </span>
      ]
    ]
  }
};

export default BDSS;