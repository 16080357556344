import React from 'react';

const Article = {
  date: '2023-07-19',
  title: 'Ensuring Your Online Experience is Consumer Ready ',
  image: {
    src: 'media/linkedin.png',
    alt: 'Consumer Ready Online Experience'
  },
  content: (<>
    <p>Consumers have been embracing digital experiences for a number of years now. However, since covid hit and as technology continues to evolve, large numbers of people have been sold on the ease and convenience of browsing and shopping online. It’s more important than ever to ensure that your website lives up to expectation, so it can compete, convert and assist in selling to audiences. Most audiences have a high level of expectation and want to view relevant, engaging content if they are to consider and choose to buy from your business.</p>

    <p>Below are a just a couple of ways to make sure your online experiences are consumer ready.</p>

    <h5>Fully Responsive Website</h5>
    <p>Opening websites on desktop computers is becoming a rare occurrence for the average consumer. On average, approximately 53% of people tend to browse b on smartphones, laptops, and tablets. When they come to a website that is not responsive for their device, more times than not, they will instantly leave your site, causing you to lose potential customers in seconds.</p>

    <h5>Launch Engaging Digital Campaigns</h5>
    <p>Unfortunately, we’re still seeing many businesses digital campaigns fall short of their physical experiences.With today’s consumers craving personal online experiences, your digital campaigns are super important and how content is displayed and served it absolutely paramount.Providing audiences with engaging, personalised content and creative that appeals and resonates is vital.When your physical and digital experiences work together seamlessly, you’ll find that conversion and the level of engagement will be more than it ever was before.</p >

    <p>If you are looking for a new website, wish to update your current online experience or simply want advice, then speak to one of our team.PURPOSE Marketing creates consumer centric websites and creative campaigns that not only look beautiful – they get results. </p >
  </>)
};

export default Article;
