import React from 'react';

import video_website from "./purpose_marketing_festivale_website_experience.mp4"; 
import video_social from "./purpose_marketing_festivale_social_media.mp4"; 
import thumb from "./purpose_marketing_case_studies_festivale.webp";
import logo from "./festivale_logo_stacked.svg";

const Festivale = {
  id: 'festivale',
  client: 'Festivale',
  summary: 'Brand Transformation + 2020 Campaign',
  logo: logo,
  thumbnail: thumb,
  thumbLarge: true,
  breif_overview: [
    'Festivale is Tasmania’s Premium Food, Beverage & Entertainment Event. PURPOSE Marketing were tasked with devising a marketing strategy to achieve targets and provided efficiencies.'
  ],
  what_we_did: [
    'An integrated marketing strategy, coupled with a considered plan for execution was put into place. As part of the strategy, specific event experiences were showcased. The brand evolved to be clean, bold, bright with a clear tone of voice. Marketing materials across traditional, digital and social channels were used executed, underpinned by a carefully curated media plan.  ',
    'The website experience and content created allowed visitors to find information with ease, coupled with the ability to: view stall menu’s, event layout, program schedules and purchase tickets at any time. The experience embraced by visitors and converted traffic into sales. '
  ],
  showcase: [
    {
      video: video_website,
      desc: 'Website Experience'
    },
    {
      video: video_social,
      desc: 'Social Media Activity'
    }
    // {
    //   video: movie2,
    //   desc: 'PURPOSE Marketing created product packaging that allowed Pandani to differentiate themselves from competitors, stand out on shelves and appeal to audiences.'
    // },
    // { image: dolly }
  ],
  results: {
    style: 'double',
    data: [
      [
        <span class="lg">+41%</span>, 
        <span class="md">increase in ticket sales </span>,
        <span class="md">Sold pre-event</span>,
        <span class="sm alt">compared to previous years </span>
      ],
      [
        <span class="lg">Sold Out</span >,
        <div><span class="sm">Reserved Tables </span><span class="sm alt">– Sold within 3 weeks</span></div>,
        <div><span class="sm">Guest Chef Lunch </span><span class="sm alt">– Sold within 7 days</span></div>,
        <div><span class="sm">All Masterclasses </span><span class="sm alt">– sold prior to gates opening</span></div>

      ]
    ]
  }
};

export default Festivale;