import React from 'react';

import video_packaging from "./purpose_marketing_rhuby_delights_packaging.mp4"; 
import video_social from "./purpose_marketing_social_media.mp4"; 
import video_website from "./purpose_marketing_rhuby_delights_online_store.mp4"; 
import image_press from "./purpose_marketing_rhuby_delights_press_ads.webp"; 
import image_brochure from "./purpose_marketing_rhuby_delights_instore_poster_and_promotional_brochure.webp"; 
import image_logo from "./purpose_marketing_rhuby_delights_logo.webp"; 


import thumbnail from "./purpose_marketing_case_studies_rhuby_delights.webp";

const Rhuby = {
  id: 'rhuby',
  client: 'Rhuby Delights',
  summary: 'Re-brand + Packaging',
  logo: {},
  thumbnail: thumbnail,
  thumbLarge: true,
  breif_overview: [
    'Rhuby Delights, a chocolatier based in Tasmania, was reliant on events as one of their sales channels. Due to covid and cancellation of events, the business needed a different approach to continue growth and achieve targets, so PURPOSE Marketing were engaged to do just that. '
  ],
  what_we_did: [
    'Specialists in FMCG and consumer/shopper behaviour, our team completed research to re-confirm category insights. A robust marketing strategy and digital plan was created and executed. ',
    'The new logo, brand and identity reflects the brand personality exceptionally well and also reflected the product itself, it was embraced by all audiences. ',
    'A website that put the customer first was designed, easy to navigate, explore and shop, with a UX and UI that visitors accepted and understood The website also reflected the fun, quirky behaviour of the brand.'
  ],
  showcase: [
    {
      image: image_logo,
      desc: 'Brand Logo + Strapline'
    },
    {
      video: video_packaging,
      desc: 'Packaging'
    },
    {
      video: video_website,
      desc: 'Online Store'
    },
    {
      video: video_social,
      desc: 'Social Media Activity'
    },
    {
      image: image_press,
      desc: 'Press Ad'
    },
    {
      image: image_brochure,
      desc: 'Promotional Brochure'
    }
    
    // {
    //   video: movie2,
    //   desc: 'PURPOSE Marketing created product packaging that allowed Pandani to differentiate themselves from competitors, stand out on shelves and appeal to audiences.'
    // },
    // { image: dolly }
  ],
  results: {
    style: 'single',
    data: [
      [
        <span class="lg">Brand </span>,
        <span class="md">Identity &amp; Packaging </span >,
        <span class="sm alt">Loved by retailers &amp; consumers</span >

      ]
    ]
  }
};

export default Rhuby;