import React from 'react';

import video_website from "./purpose_marketing_launceston_greyhound_racing_club_website_experience.mp4"; 
import image_logo from "./purpose_marketing_launceston_greyhound_racing_club_logo.webp"; 
import thumbnail from "./purpose_marketing_case_studies_launceston_greyhound_racing_club.webp";
import thumbmobile from "./purpose_marketing_case_studies_launceston_greyhound_racing_club-mobile.webp";

const LGRC = {
  id: 'lgrc',
  client: 'LGRC',
  summary: 'Logo Creation + Website',
  logo: {},
  thumbnail: thumbnail,
  thumbMobile: thumbmobile,
  thumbLarge: false,
  breif_overview: [
    'The Launceston Greyhound Racing Club logo and website were tired, they hadn’t been updated for a number or years. There was a need to refresh the brand to increase visitation and appeal to key audiences. '
  ],
  what_we_did: [
    'Our team used their collective knowledge and experience to create an overall brand experience that was fresh, bold and that would appeal to key / primary audiences to be targeted.',
    'The branding created was bold, relevant and utilised colours and shapes that we knew would appeal, excite and be relevant. The website experience created was created with customer centric, visual, easy to navigate and find information without having to scroll or search. '
  ],
  showcase: [
  
    {
      image: image_logo,
      desc: 'Brand Logo'
    },
    {
      video: video_website,
      desc: 'Website Experience'
    }
  ],
  results: {
    style: 'double',
    data: [
      [
        <span class="lg">A bold</span>, 
        <span class="md"> bright brand experience</span>
      ],
      [
        <span class="lg">Visitation</span>, 
        <span class="md"> on weekly race nights</span>, 
        <span class="sm alt">Is continuing to increase </span>
      ]
    ]
  }
};

export default LGRC;