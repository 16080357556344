import React from 'react';

import image_packaging from "./purpose_marketing_shima-wasabi_packaging.webp"; 
import video_imagery from "./purpose_marketing_shima-wasabi_Imagery.mp4"; 


import thumbnail from "./purpose_marketing_case_studies_shima.webp";

const Shima = {
  id: 'shima',
  client: 'Shima Wasabi',
  summary: 'Product Packaging + Launch',
  logo: {},
  thumbnail: thumbnail,
  thumbLarge: true,
  breif_overview: [
    'PURPOSE Marketing were given the task of launching Shima Wasabi Paste nationally and were only too pleased to rise to the challenge. '
  ],
  what_we_did: [
    'Staying true to the brand, its product authenticity and natural colour, we selected a colour palette and designed product labels that could be applied with ease',
    'With the product being small in size, our team wanted to give the product further presence on shelf, so manipulated the shape of the box, by extending the box back. This provided extra height on shelf, plus double the space for content, giving the box an added dimension and increased box strength.',
    'The product was launched across various digital channels and support imagery was produced to showcase the ways in which the product can be used.'
  ],
  showcase: [
    {
      image: image_packaging,
      desc: 'Packaging'
    },
    {
      video: video_imagery,
      desc: 'Photography + Imagery Creation'
    }
  
    // {
    //   video: movie2,
    //   desc: 'PURPOSE Marketing created product packaging that allowed Pandani to differentiate themselves from competitors, stand out on shelves and appeal to audiences.'
    // },
    // { image: dolly }
  ],
  results: {
    style: 'single',
    data: [
      [
        <span class="lg">Stockist </span>,
        <span class="md">Numbers continue to grow </span >,
        <span class="sm alt">Consumers love the new product </span >

      ]
    ]
  }
};

export default Shima;