import React from 'react';

import video_photoshoot from "./purpose_marketing_meander_valley_dairy_cheese_sleeves.mp4"; 

import thumbnail from "./purpose_marketing_case_studies_launceston_mvd.webp"; 
import thumbmobile from "./purpose_marketing_case_studies_launceston_mvd-mobile.webp"; 


const MVD = {
  id: 'mvd',
  client: 'Meander Valley Dairy',
  summary: 'Product Packaging + Launch',
  logo: {},
  thumbnail: thumbnail,
  thumbMobile: thumbmobile,
  thumbLarge: false,
  breif_overview: [
    'PURPOSE marketing received a brief to launch a new range of cheese to sit upon shelves in supermarkets and selected retailers throughout Australia. '
  ],
  what_we_did: [
    'With the cheese market being cluttered and exceptionally competitive, the product needed to stand out and be seen on shelves, if it was to be considered and purchased. ',
    'It made perfect sense to our team to work with the product shape and embrace it. So, we created a “cheese sleeve” that would help to both protect and showcase the shape of the product. ',
    'Launching four product sku’s into market, we designed each sleeve to feature illustrations and selected colours that were relevant to that specific cheese flavour, appealing to both retailers and consumers. '
  ],
  showcase: [
    {
      video: video_photoshoot,
      desc: 'Packaging'
    }
    // {
    //   video: movie2,
    //   desc: 'PURPOSE Marketing created product packaging that allowed Pandani to differentiate themselves from competitors, stand out on shelves and appeal to audiences.'
    // },
    // { image: dolly }
  ],
  results: {
    style: 'single',
    data: [
      [
        <span class="lg">Product </span>,
        <span class="md">is now on retail shelves </span >,
        <span class="sm alt">sales are continuing to increase </span >

      ]
    ]
  }
};

export default MVD;