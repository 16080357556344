import React from 'react';

import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";


import {ReactComponent  as StragtegyIcon} from './media/icon_strategy.svg';
import {ReactComponent  as CreativeIcon} from './media/icon_creative.svg';
import {ReactComponent  as DigitalIcon} from './media/icon_digital.svg';
import {ReactComponent  as SocialIcon} from './media/icon_social.svg';
import {ReactComponent  as TechnologyIcon} from './media/icon_technology.svg';
import {ReactComponent  as ProductionIcon} from './media/icon_production.svg';

import PageHeader from 'components/page_header/PageHeader';

import './07_services.scss';



const ServicesPage = (props) => {
  React.useEffect(() => {
    // ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

    var isCrApple = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

    new TimelineMax().timeScale(1)

      // .to(".ms", { duration: 0, top: "-400px","padding-bottom": "214px", color: "#105cab"})
      // .to(".tg", {duration: 0, opacity: 0})
      // .to(".r", {duration: 0, left: '2000px', background:"black"})
      // .to(".decoration", {left: '2000px'})
      .to(".decoration", { left: (420 / 12.80)+'%' })
      .to(".ocs", { left: 280/12.80+'%' })
      .to(".words", { left: 166/12.80+'%' })
      .to(".words span:nth-child(1)", { opacity: 1 })
      .to(".words span:nth-child(2)", { opacity: 1 })
      .to(".words span:nth-child(3)", { opacity: 1 }, "<")
      .to(".words span:nth-child(4)", { opacity: 1 })
      .to(".words span:nth-child(5)", { opacity: 1 },"<")
      .to(".words span:nth-child(6)", { opacity: 1 })
      .to(".words span:nth-child(7)", { opacity: 1 },"<")
      .to(".words span:nth-child(8)", { opacity: 1 })
      .to(".words span:nth-child(9)", { opacity: 1 }, "<")
      .to(".decoration2", { right: 186/12.80+'%' })
      .to("#line01", { height: 'calc(100% + 200px + 20px'+((isCrApple) ? ' + 1px' : '')+')'})
      .to("#line02", { width: '4'+((isCrApple) ? '5' : '4')+'%'})
      .to("#line03", { height: '88px'})



  // let scene = new ScrollMagic.Scene(
  //   {triggerElement: "#magicWrap",
  //   duration: 0,
  //   triggerHook: 0})
  //     // .setPin("#pageheader")
  //     .setTween(tween) // the tween durtion can be omitted and defaults to 1
  //     .addTo(servicesController);

  },[]);

  return (
    <>
    <div id="magicWrap" >
    </div>
    <PageHeader>
      <div className="services-one">
        <div className="decoration" />
        <div className="ocs">Our Core Services</div>
        <div className="words">
          <span>digital</span>
          <span>+</span>
          <span>creative</span>
          <span>+</span>
          <span>strategy</span>
          <span>+</span>
          <span>social media</span>
          <span>+</span>
          <span>production</span>
        </div>
        <div className="decoration2" />
      </div>
    </PageHeader>
    <section id="services">
      <div className="anchor" id="_services"></div>
      <div className="container">

          <h6>PURPOSE Marketing offers a full range of services to clients including strategic, creative and digital.</h6>

        <div className="media">
          <div>
            <video loop autoPlay muted playsInline>
               <source src="./media/vid_all2.mov" type="video/mp4" />
           </video>
           <span></span>
          </div>

        </div>
        <span className="copy">
            <p>Our team review, identify and utilise appropriate channels to ensure our marketing solutions work and serve a purpose.<br /><br />
              We do what’s right for the client, every time.</p>
        </span>

        <div className="tagline">
          <span>If you need </span>
          <span>Marketing assistance or advice</span>
          <span>We can help</span>
        </div>

        <ul>
          <li>
            <StragtegyIcon />
            <span>
              <h3>STRATEGY</h3>
              <p>Behind a great business is a considered,<br /> integrated strategy.</p>
              <span>
                Brand <br />
                Marketing + corporate sales <br />
                Digital + social<br />
                Research (qualitative + quantitative) <br />
                Shopper/consumer behaviour
              </span>
            </span>
          </li>
          <li>
            <CreativeIcon />
            <span>
              <h3>CREATIVE + DESIGN</h3>
              <p>Beautiful. Relevant. Powerful enough<br /> to create connection &amp; evoke emotion.</p>
              <span>
                Brand creation + re-branding <br />
                Logo creation <br />
                Brand identity <br />
                Sales + promotional collateral
              </span>
            </span>
          </li>
          <li>
            <DigitalIcon />
            <span>
              <h3>DIGITAL</h3>
              <p>Online experiences that mirror the physical. <br />Take your customers on a journey.</p>
              <span>
                Website creation <br />
                Site re-development + maintenance <br />
                Traffic + data analysis <br />
                Seo + sem + display <br />
                App development
              </span>
              </span>
          </li>
          <li>
            <SocialIcon />
            <span>
              <h3>SOCIAL MEDIA</h3>
              <p> Connect, communicate &amp; unite with audiences. Build strong relationships.</p>
              <span>
                Content creation + curation <br />
                Engagement planning <br />
                Campaign management <br />
                Monitoring + analysis + reporting
              </span>

            </span>
          </li>
          <li>
            <TechnologyIcon />
            <span>
              <h3>NEW TECHNOLOGY</h3>
              <p>Understand, connect &amp; sell to customers.<br /> User-centric experiences that help business.</p>
              <span>
                NFC I.Beacon marketing<br />
                Augmented reality <br />
                NLP, Machine Learning &amp; AI<br />
                Touch screen experiences
              </span>
            </span>
          </li>
          <li>
            <ProductionIcon />
            <span>
              <h3>PRODUCTION</h3>
              <p> Provide experiences that make an impression.<br />High quality at a competitive cost.</p>

              <span>
                Tv & radio content creation <br />
                Photography + video <br />
                Signage + packaging <br />
                Printing <br />
                Point of sale + ticketing
              </span>
            </span>
          </li>
        </ul>
      </div>
    </section>
    </>
  );

}

export default ServicesPage;
