import React from 'react';

const Article = {
  date: '2022-04-27',
  title: 'We are PURPOSE',
  // linkedInLink: 'https://www.linkedin.com/posts/purposemarketing-australia_purposemarketing-marketingdigital-digitalmarketingagency-activity-6928235904163614720-SAbd',
  image: {
    src: 'media/01 WE ARE PURPOSE.jpg',
    alt: 'We are PURPOSE'
  },
  content: (<>
    <p>PURPOSE Marketing is a digital and creative agency that’s based in Launceston, Tasmania. We’re driven by providing marketing solutions that get results for our clients. </p>

    <p>The world is changing, and now more than ever, marketing activity needs to deliver better results. At PURPOSE, we understand this more than most. Since our inception, we’ve been striving to redefine marketing in Tasmania and the rest of Australia by providing solutions that connect the digital and the physical. </p>

    <p>When digital and social marketing began to emerge, some marketeers fell victim to completely separating digital and physical activity – some still do to a degree. At PURPOSE Marketing, we don’t. We look at things differently, we craft and build marketing solutions by using whichever channels will be the most effective, serve a specific purpose, and get results.</p>

    <p>Coming up with creative ways to seamlessly fuse the digital world with the real world is where we as a marketing agency excel. We are able to work with businesses in almost any category but specialise in: retail, events, food & beverage, travel & hospitality, consumer brands and business to business. </p>
  </>)
};

export default Article;
