import React from 'react';

const Article = {
  date: '2023-07-24',
  title: 'Data is Opportunity',
  image: {
    src: 'media/01 Data Is Opportunity linkedin[1].jpg',
    alt: 'Data is Opportunity'
  },
  content: (<>
    <h5>Use it to gain advantage</h5>
    <p>As people continue to explore, purchase and engage in a forever changing digital world, most businesses are able to collect more data than ever before. A number of businesses shy away from reviewing data or don’t give it much thought. This is often due to the fact that they’re busy, time poor or they simply don’t understand what to do with it. </p>
    <p>Data can be one of the most helpful resources in providing insight and fuel for marketing and sales strategies. </p>
    <p>Below are only a couple best practices for effective, data-driven marketing that could help your business to gain advantage. </p>

    <h5>Take advantage of the new data you’re obtaining.</h5>
    <p>Online shopping and booking data aren’t just names and details of people that have engaged with your business.  It’s data and detail which could provide new opportunity for customers to engage and buy again, provide referrals and repeat visitation</p>

    <h5>Look at your data as a whole.</h5>
    <p>For most retail, food and beverage businesses, data floods in from CRM platforms, website analytics, social media channels, email marketing platforms and other channels. Being able to compile all of this data and understand it holistically will give your business a better understanding of your audiences, allow you to have actual data and fact to support the reasoning for future marketing efforts and activity. </p>

    <h5>Continue to monitor and optimise.</h5>
    <p>When it comes to marketing, you cannot have a set-and-forget-approach if you’re to maximise results. Google Ads Campaigns, you are given real-time data, so you can adjust bids, re-evaluate keywords, and more. With social media, you can see which kind of posts are working and which are not. eDM audiences need to be updated and segmented to get the best engagement rates. If you’re not checking in and monitoring your campaign data on a regular basis, you’re most likely missing out on opportunities and campaigns running will not be optimised to their fullest potential. </p>
    <p>Purpose Marketing doesn’t create marketing solutions or campaigns based on gut instinct – we use data and insight to ensure that everything we do has purpose and gets real results. And now, our clients and other businesses are able to receive and access more data than ever before. If you’re struggling to fully understand your data or need assistance, we’d love to help.</p>
  </>)
};

export default Article;
