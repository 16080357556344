import React from 'react';

const Article = {
  date: '2022-05-01',
  title: 'Every Business Needs A Purpose. Have you found yours?',
  // linkedInLink: 'https://www.linkedin.com/posts/purposemarketing-australia_brandpurpose-purposemarketing-activity-6686120297923510272-BtKW/',
  image: {
    src: 'media/02 EVERY BUSINESS NEEDS.jpg',
    alt: 'PURPOSE road sign'
  },
  content: (<>
    <p>Your potential and existing customers may already know what you sell or can find this information, but customers also need to know why you exist apart from just to make money. </p>

    <p>This all comes down to your brand purpose - what makes your brand different from the rest in regard to changing society? What differentiates your business from others? What difference will that make?</p>

    <p>While you may have an idea in your head as to what your brand’s purpose is, actually understanding what that means to your business and to your audiences is what will allow you to create deeper connections and relationships with existing and potential audiences. </p>

    <p>Here are just a couple things to consider when reshaping or finding your purpose. </p>

    <h5>Make sense.</h5>
    <p>Your brand purpose is what could make you stand out from competitors – while you may sell a similar or identical product or service, if audiences can see that if they choose your brand, they are also helping to support a “purpose” that they also care for and can support, it can affect more than sales and your bottom line. </p>

    <h5>Be authentic.</h5>
    <p>Don’t just follow what is trending or follow the path of another competitor. Your customers will see through that, and this could potentially damage your business rather than helping it.</p>

    <p>Instead, sit down and have a good think about what your brand is all about and what you believe. When you create your own unique brand purpose, focus on what matters to you, even if it’s not something that isn’t a hot topic or something that you think others may immediately warm to.</p>

    <p>When you find your purpose and understand it, you can create deeper, more emotional connections with your staff and your customers, which could increase customer and staff loyalty. As importantly, you will know that your brand is helping to serve its own purpose in a world that has challenges and is forever changing. </p>
      </>)
};

export default Article;
