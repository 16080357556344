import { default as News_20220427 } from './20220427_we_are_purpose';
import { default as News_20220501 } from './20220501_every_business_needs_purpose';
import { default as News_20220504 } from './20220504_people_with_purpose';
import { default as News_20220509 } from './20220509_retail_experience';
import { default as News_20220511 } from './20220511_social_media_matters';
import { default as News_20230530 } from './20230530_brands_business_chat_gpt';
import { default as News_20230719 } from './20230719_ensuring_your_online_experience_is_consumer_ready';
import { default as News_20230724 } from './20230724_data_is_opportunity';
import { default as News_20230731 } from './20230731_evaluate_optomise_for_better_results';
import { default as News_20230810 } from './20230810_value_of_content';
import { default as News_20231221 } from './20231221_christmas';
import { default as News_20240214 } from './20240214_mastering_channel_assessment';
import { default as News_20240219 } from './20240219_improving_customer_service'; 


const Articles = [ 
  News_20240219,
  News_20240214,
  News_20231221,
  News_20230810,
  News_20230731,
  News_20230724,
  News_20230719,
  News_20230530,
  News_20220511,
  News_20220509,
  News_20220504,
  News_20220501,
  News_20220427,
];
export default Articles;
