import React from 'react';

import video_signage from "./purpose_marketing_pyengana_dairy_navigational_signage_exterior_and_interior.mp4"; 
import video_website from "./purpose_marketing_pyengana_dairy_online_store.mp4"; 
import video_photoshoot from "./purpose_marketing_pyengana_dairy_photoshoot.mp4"; 
import thumbnail from "./purpose_marketing_case_studies_pyengana_dairy.webp";
import thumbmobile from "./purpose_marketing_case_studies_pyengana_dairy-mobile.webp";
import logo from "./logo_pyengana.svg";

const Pyengana = {
  id: 'pyengana',
  client: 'Pyengana Dairy',
  summary: 'Digital + Physical brand Experience',
  logo: logo,
  thumbnail: thumbnail,
  thumbMobile: thumbmobile,
  thumbLarge: false,
  breif_overview: [
    'The Pyengana Dairy is located in the scenic Pyengana Dairy Valley, attracting a large number of people on a daily basis. The signage was old and needed to change in order for visitors to find their way ‘round with ease and on-site sales needed to increase.'
  ],
  what_we_did: [
    'PURPOSE Marketing undertook an audit, observed consumer/shopper behaviour and armed with valuable industry insight, put a plan into place to create navigational and educational signage that would enhance the experience of all visitors, both physical and digital. ',
    'We designed and implemented a range of materials: interior and exterior on-site signage, promotional materials and OOH billboards. Our team also styled and produced a series of images for that showcased products, prompting audiences to take a specific action or purchase.'
  ],
  showcase: [
    {
      video: video_signage,
      desc: 'Navigational Signage, Exterior + Interior'
    },
    {
      video: video_website,
      desc: 'Online Store'
    },
    {
      video: video_photoshoot,
      desc: 'Photography + Imagery'
    }
    // {
    //   video: movie2,
    //   desc: 'PURPOSE Marketing created product packaging that allowed Pandani to differentiate themselves from competitors, stand out on shelves and appeal to audiences.'
    // },
    // { image: dolly }
  ],
  results: {
    style: 'single',
    data: [
      [
        <span class="lg">on-site</span>, 
        <span class="md">+ online sales</span>,
        <span class="sm alt">increased from the first week </span>,
        <span class="sm alt">of signage/online store being implemented </span>
      ]
    ]
  }
};

export default Pyengana;