import React from 'react';

import { Link, useLocation  } from "react-router-dom";

import {ReactComponent  as Logo} from './media/purpose_logo.svg';
// import {ReactComponent  as LinkedInLogo} from './media/iconmonstr-linkedin-3.svg';
import {ReactComponent  as LinkedInLogo} from 'global/media/icon_linkedin.svg';
import {ReactComponent  as FacebookLogo} from 'global/media/icon_facebook.svg';

import './01_header.scss';

const Header =(props) => {
  let location = useLocation();
  let current = "current_";

  const doClick = () => {
    //  iOS doesn't properly support anchors because utter morons
    var body = document.body,
    html = document.documentElement;

    var heightOffset = (Math.max( body.offsetWidth, html.clientWidth,html.offsetWidth) < 735) ? 1050 : 900;

    var height = Math.max( body.scrollHeight, body.offsetHeight,
                       html.clientHeight, html.scrollHeight, html.offsetHeight );
    document.documentElement.scrollTop = height - heightOffset;
  }

  if ('pathname' in location) {
   current += location.pathname.split("/")[1];
  }

  // const contactLink = 'http://james-notebook:1234/#_contact';//location.pathname + ((location.pathname.slice(-1) != '/') ? '/' : '') + '#_contact';
  // React.useMemo(() => svgReplace, []);

/*
(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';}(jQuery));var $mcj = jQuery.noConflict(true);
*/

//<a href="https://www.facebook.com/purposemarketingtas/" target="_blank" title="Facebook"><FacebookLogo /></a>

  return(
    <header>
      <div className="container ">
        <nav className={current}>
          <Link to="/services">Services</Link>
          <Link to="/work">Work</Link>
          <Link to="/careers">Careers</Link>
          <Link to="/insights">Insights</Link>
          <a onClick={doClick}  className="contact-trigger">Contact</a>
          <div className="ind" />
        </nav>

        <h1 id="logo-primary">
          <Link to="/"><Logo className="svg" alt="PURPOSE Marketing" title="PURPOSE Marketing" /></Link>
        </h1>

        <span className="strapline">Digital + creative agency</span>

        <div className="follow" >
          <span>Follow Us</span>
            <a href="https://www.facebook.com/purposemarketingtas" target="_blank" title="Facebook"><FacebookLogo /></a>
            <a href="https://www.linkedin.com/company/purposemarketing-australia/?viewAsMember=true" target="_blank" title="Linked In"><LinkedInLogo /></a>
        </div>
      </div>
    </header>
  );

}

export default Header;
