// import { default as CS_TasFoods } from './tasfoods';
import WesternTiersDistillery from './wtd/wtd';
import Hurricanes from './hurricanes/hurricanes';
import Festivale from './festivale/festivale';
import Pyengana from './pyengana/pyengana';
import Pandani from './pandani/pandani';
import Betta from './betta/betta';
import Indulge from './indulge/indulge';
import Igloo from './igloo/igloo';
import BDSS from './bdss/bdss';
import LGRC from './lgrc/lgrc';
import Rhuby from './rhuby/rhuby';
import MVD from './mvd/mvd';
import Shima from './shima/shima';

const CaseStudies = [
  WesternTiersDistillery,
  Hurricanes,
  Festivale,
  Pyengana,
  Pandani,
  Betta,
  Indulge,
  Igloo,
  BDSS,
  LGRC,
  Rhuby,
  MVD,
  Shima
];
const Layout = [
  [
    WesternTiersDistillery, 
    Betta,
    Festivale, 
    Pyengana
  ],
  [
    Hurricanes,
    BDSS,
    Pandani,
    Rhuby,
  ],
  [
    Indulge,
    Igloo,
    Shima,
    MVD,
    LGRC
  ]
]
export default CaseStudies;
export { Layout };
