import React from 'react';

import { useParams, useHistory } from "react-router-dom";

import $ from 'jquery';

// import SliderRevolution from 'components/slider_revolution/SliderRevolution';

import CaseStudies from './data';
import WorkNav from './WorkNav';


import './workDetail.scss';

import {ReactComponent  as Chevron} from './media/arrow.svg';
import {ReactComponent  as Chevron2} from './media/arrow2.svg';
import {ReactComponent  as Close} from './media/close.svg';
import { ReactComponent as Play } from './media/play.svg';


function getResultsMarkup(CS) {
  let rtn = [];
  if (CS.results) { // && CS.results.style === 'double'
    rtn = CS.results.data.map(result =>
      <li>
        {result.map(x => (<>{x}</>))}
      </li>
    );
  }

  return (<ul>{rtn}</ul>);
}


function getDetailsMarkup(CS) {
  return (
    <>
      <h6>Brief Overview</h6>
      {CS.breif_overview.map(p => <p>{p}</p>)}
      <h6>What we did</h6>
      {CS.what_we_did.map(p => <p>{p}</p>)}
    </>
  );
}

function isIOS(){ 
  return (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream;
} 

function StartVideo(e) {
  $(e.target).parents('li').children('video').trigger('play');
  $(e.target).parents('a').hide();
}


function getMedia(item) {
  if ('image' in item) {
    return (<img src={item.image} />);
  }
  let play = (isIOS()) ? <a className="playButton" onClick={StartVideo}><Play /></a> : '';
  
  if ('video' in item) {
    return (<><video muted playsInline autoplay loop controls={'showControls' in item && item.showControls}><source src={item.video +'#t=0.1'} type="video/mp4" /></video>{play}</>);
  }
}

function getCSMarkup(CS, doShow) {
  return (<>

    <section className="workDetail">
      <div class="content">
        <div className="details">
          <div className="intro">

            <h4>{CS.client}</h4>
            {getDetailsMarkup(CS)}
          </div>

          <div className="results">
            <h3>results</h3>
            {getResultsMarkup(CS)}
          </div>

        </div>

        <ul className="showcase">
          {CS.showcase && CS.showcase.map(item => (<li key={'sc'+CS.showcase.indexOf(item)}>
            { getMedia(item)  }
            
            <span>{item.desc}</span>
          </li>))}
        </ul>
      </div>
      <nav className="showcase">
        <a className="showmore" onClick={doShow('more')}>show more</a>
        <a className="fwd" onClick={doShow('more')}><Chevron2 /></a>
        <a className="none" onClick={doShow('none')}><Close /></a>
        <a className="back" onClick={doShow('less')}><Chevron /></a>
      </nav>
    </section>
  </>);
}


const WorkDetailPage = (props) => {

  let { category, study } = useParams();
  let history = useHistory();


  let [incoming, setIncoming] = React.useState();
  let [outgoing, setOutgoing] = React.useState();
  let [trans, setTrans] = React.useState(-1);
  let [dir, setDir] = React.useState('fwd');
  const showcaseNumRef = React.useRef(0);
  let [showcaseNum, setShowcaseNum] = React.useState(0);
  const setShowcaseNumRef = React.useRef(setShowcaseNum);
  //showcaseNumRef.current = showcaseNum;

  const CSDetail = CaseStudies.find(cs => cs.id === study);
  let [current, setCurrent] = React.useState();


  const currentIndex = CaseStudies.indexOf(CSDetail);
  const offsetCaseStudies = [...CaseStudies.slice(currentIndex), ...CaseStudies.slice(0,currentIndex)]; // offest to make current first in array

  const CSnext = offsetCaseStudies.find(cs => cs !== CSDetail);
  const CSprev = offsetCaseStudies.reverse().find(cs => cs !== CSDetail);


  const doShow = (task) => () => {
    let targ = 0;
    switch (task) {
      case 'more':
        targ = showcaseNumRef.current+1;
        break;
      case 'less':
        targ = showcaseNumRef.current-1;
        break;
      case 'none':
        targ = 0;
    }
    targ = Math.min(Math.max(0, targ), current.showcase.length-1);
    setShowcaseNum(targ);
    showcaseNumRef.current=targ;
    VideoAutoplay();
  }

  // const doShowMore = () => {
  //   setShowcaseNum(showcaseNum+1);
  //   showcaseNumRef.current++;
  //   if (showcaseNumRef.current >= current.showcase.length) {
  //     setShowcaseNum(current.showcase.length-1);
  //     showcaseNumRef.current=current.showcase.length-1;
  //   }
  // }


  const doCloseMore = () => {
    if (showcaseNumRef.current > 0) {
      showcaseNumRef.current--;
      setShowcaseNum(showcaseNumRef.current);
      setTimeout(() => doCloseMore(), 500);
    }
  }

  const doProjectNav = (dir) => {
    setDir(dir);
    let timer = 1;
    if (showcaseNumRef.current !== 0) {
      timer = 500;
      doShow('none')();
    }
    setTimeout(() => {
      history.push('/work/'+((dir === 'fwd') ? CSnext.id : CSprev.id));
    }, timer);

  }

  const VideoAutoplay = () => {
    setTimeout(() => {


      let all_videos = $('.showcase video');
      let current = $('div.current').attr('data-showcase-display');
      all_videos.each((ii, vid) => {
        // $(vid).hide();
          $(vid).trigger('pause');
          $(vid).parent().children('a').show();
      });
      $('div.current .showcase li').each((ii, li) => {
        if (ii == current && !isIOS()) { //
           $(li).children('video').trigger('play'); 
        }
      });



    }, 500);
  }



  React.useEffect(() => {
    // console.log(current);
    // console.log(outgoing);
    setDir((current === CSprev) ? 'fwd' : 'rev');
    setOutgoing(current);
    setCurrent(CSDetail);
    setTrans(trans+1);
    VideoAutoplay();
  }, [study]);




  return (
    <div className="wrapper">
      <WorkNav projectNavRef={doProjectNav} />

      <div key={trans} className={'wrappera '+((trans !== 0) ? 'play' : '')+' '+dir}>
      <div class="cube">
          <div class="current" data-showcase-display={showcaseNum} data-showcase-total={current && current.showcase.length}>
          {current && getCSMarkup(current, doShow)}
        </div>
          <div class="outgoing" data-showcase-display={showcaseNum} data-showcase-total={outgoing && outgoing.showcase.length}>
          {
            outgoing !== undefined && getCSMarkup(outgoing, doShow)
          }
        </div>
      </div>
      </div>
      <WorkNav projectNavRef={doProjectNav} />

    </div>
  );

}

export default WorkDetailPage;
