import React from 'react';

const Article = {
  date: '2023-07-31',
  title: 'Evaluate + Optimise For Better Results',
  image: {
    src: 'media/08 EVALUATE + OPTIMISE.jpg',
    alt: 'Evaluate + Optimise For Better Results'
  },
  content: (<>
    <p>When it comes to marketing, you should not have a set-it-and-forget-it approach.</p>
    <p>Successful marketing requires regular evaluation of content, creative and the ability to optimise campaigns and messaging if your business is to achieve the best possible results. </p>

    <h5>Look at your data as a whole</h5>
    <p>For most businesses, data floods in from your CRM’s, website analytics, social media channels, POS systems, email marketing platforms and more. This data in most cases is stored in a number of places on a server and this useful data then sits in separate databases. Consolidation and segmenting those databases will provide efficiencies, allowing you to get a holistic view of audience groups VS having to view data individually where only some or part of the data may be used or considered.  </p>

    <h5>Evaluate + Optimise for Better Results</h5>
    <p>In the digital world in which we live, it’s now easier than ever to view insights, data and make changes that can fuel success. Google Ads Campaigns can be viewed in real-time, so you can adjust bids, re-evaluate keywords, and so much more. With most social media platforms, you are able to view posts, data and gain insight surrounding which ads/posts are contributing towards targets and as importantly, those that are not.  </p>
    <p>If you are not taking time to evaluate the data that you have and are not optimising campaigns on a regular basis, it’s likely that results achieved could be better if you were to evaluate and optimise. </p>
    <p>PURPOSE Marketing understand that you may not always have the time or ability to undertake this work yourselves. Should you need advice or would like to talk about how we can assist your business then we’d love to hear from you. </p>

  </>)
};

export default Article;
