import React from 'react';

import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

import {ReactComponent  as StragtegyIcon} from './media/icon_strategy.svg';
import { ReactComponent as CreativeIcon } from './media/icon_creative.svg';
import { ReactComponent as DigitalIcon } from './media/icon_digital.svg';
import { ReactComponent as SocialIcon } from './media/icon_social.svg';
// import {ReactComponent  as TechnologyIcon} from './media/icon_technology.svg';
// import {ReactComponent  as ProductionIcon} from './media/icon_production.svg';



import PageHeader from 'components/page_header/PageHeader';

import './careers.scss';

// let controller = new ScrollMagic.Controller();


const CareersPage = (props) => {
  const [open, setOpen] = React.useState(-1);

  React.useEffect(() => {
    // ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
    var isCrApple = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    // build tween
    var tween = new TimelineMax().timeScale(1)
      .to({}, 1, {})
      // .to(".ms", { duration: 0, top: "-400px","padding-bottom": "214px", color: "#105cab"})
      // .to(".tg", {duration: 0, opacity: 0})
      // .to(".r", {duration: 0, left: '2000px', background:"black"})
      // .to(".decoration", {left: '2000px'})

      .to(".decoration", { left: (420 / 12.80) + '%' })
      .to(".wwu", { left: (296 / 12.80) + '%' }, "<")
      .to(".fy", { left: (490 / 12.80) + '%' })
      .to(".p", { transform: 'rotateX(0deg)' })
      .to(".decoration2", { left: 546 / 12.80 + '%' })
      .to("#line01", { height: 'calc(100% + 200px + 20px' + ((isCrApple) ? ' + 1px' : '') + ')' })
      .to("#line02", { width: '4' + ((isCrApple) ? '5' : '4') + '%' })
      .to("#line03", { height: '38px' })

    // let scene = new ScrollMagic.Scene(
    //     {triggerElement: "#magicWrap",
    //     duration: 0,
    //     triggerHook: 0})
    //         // .setPin("#pageheader")
    //         .setTween(tween) // the tween durtion can be omitted and defaults to 1
    //         .addTo(controller);

  }, []);

  var doOpen = (ii) => {
    if (open == ii) {
      setOpen(-1);
    } else {
      setOpen(ii);
    }
    return false;
  };





  return (
    <>
      <PageHeader>
        <div class="careers-one">
          <div className="decoration" />
          <span class="wwu">work with us</span>
          <span class="fy">+ find your</span>
          <span class="p">PURPOSE</span>
          <div className="decoration2" />
        </div>
      </PageHeader>
      <section id="careers">
        <div>
          <div className="intro">
            If you’re looking a new challenge, a new role or simply with to move your career forward, then do it with PURPOSE. 
          </div>
          <p>We don’t have any vacancies at this time, but we’re always looking for experienced, passionate and talented people that can make a difference to the agency and our clients’ business. </p>

          <p>If you believe you have what it takes to make a positive change to our agency, we’d love to hear from you. </p>



          <ul class="positions">
            
            {/* <li class={(open == 2) ? 'open' : ''}>
              <div className="icon"><CreativeIcon /></div>
              <h4>Senior Designer</h4>
              <span class="description">Full Time</span>
              <a onClick={() => { return doOpen(2); }} class="opener"><span>+</span><span>-</span></a>
              <div class="details">


                <strong>ABOUT THE COMPANY</strong>
                <p>PURPOSE Marketing is a digital and creative marketing agency based in Launceston CBD, Tasmania.</p>
                <p>Due to continued growth, we're looking for an exceptional Senior Designer who is hungry for career progression to join our team.</p>
                <p>The successful candidate will be joining an expanding results driven agency that has an established, varied portfolio of clients across Tasmania and Australia.</p>
                <p>We’re dedicated to making a real difference to business across our three core disciplines: strategy, creative and digital. As a specialist agency, our clients sit across the following categories: FMCG, Retail, F&amp;B, Luxury Brands, Travel, Events, Hospitality, &amp; B2b.</p>




                <strong>ABOUT THE ROLE</strong>
                <p>Our ideal candidate would be an exceptionally talented Senior Designer with a strong portfolio able to produce amazing work, with a genuine love for working with FMCG, retail F&amp;B brands.</p>
                <p>With a minimum of 2 years’ experience of working in a similar role, you’ll be able to hit the ground running and have experience of leading a small team.</p>
                <p>As Senior Designer, you'll be responsible for creative output of the agency. Keen to push creative and design boundaries, you’ll utilise your graphic and digital design experience to create concepts, designs and produce deliverables across multiple channels, on time and on brief.</p>
                <p>Being used to working as part of a team, you'll also be comfortable working alone when required. You thrive when working under pressure and love nothing more than being in the limelight as you present creative to internal stakeholders and clients.</p>
                <p>We work collaboratively at PURPOSE Marketing, so you’ll work with creatives, web developers, copywriters and the client service team to deliver projects and campaigns. The need to produce work to a high level will be essential, as will the ability to work on a number of projects at any given time.</p>




                <strong>EXPERIENCE</strong>
                <p>Essential for this role
                  <ul>
                    <li>Minimum of 2 years’ experience of working in an Australian design studio, marketing agency or in-house commercial environment in the same or similar senior role.</li>
                    <li>Demonstrated creative thinking.</li>
                    <li>Versatility of style and medium.</li>
                    <li>Extensive experience of working with Photoshop / Illustrator / Indesign / Adobe Suite.</li>
                    <li>Experience of producing campaign deliverables across all channels (digital, social and traditional).</li>
                    <li>Ability to create and design considered UX for websites/apps.</li>
                    <li>Excellent organisational skills, the ability to prioritise tasks and work to timelines.</li>
                    <li>Experience of managing a small team.</li>
                    <li>Excellent communication skills(written and verbal).</li>
                    <li>The ability to work well in an agile creative environment.</li>
                  </ul></p>

                <p>Desirable, but not essential (one or more of the following):
                  <ul>
                    <li>Graphic Design diploma or a degree in Visual Communication.</li>
                    <li>Experience in using After Effects / other animation/video editing software. </li>
                  </ul>
                </p>


                <strong>JOIN US</strong>
                <p>This is an ideal opportunity for an exceptional Senior Designer who is hungry for career progression and wants to move their career forward.</p>
                <p>This is a full-time role that needs to be performed on-site, working remotely will not be possible.</p>
                <p>Package will be competitive and commensurate with experience.</p>



                <strong>APPLY FOR THIS ROLE</strong>
                <p>If you think you’ve got what it takes and believe you’re the person we’re looking for, apply today and include the following:</p>
                <p>
                  <ul>
                    <li>Covering Letter, including salary expectation</li>
                    <li>Your updated resume</li>
                    <li>A portfolio or link to your work portfolio</li>
                  </ul>
                </p>
                <p>Closing date for applications: Sunday 10th September 2023.</p>

                <p>The remuneration package will be determined by experience.</p>

                <p>
                  <ul>
                    <li><strong>You must be an Australian Citizen or hold a valid visa that allows you to work in Australia to apply. </strong></li>
                    <li>All applications will be assessed as they are received.</li>
                    <li>Applications <strong>will not be accepted</strong> from overseas, recruitment agencies or outsourcing agents/businesses. </li>
                  </ul>
                </p>

                <p><strong>Please note</strong>: Only successful candidates will be contacted.</p>

              </div>
            </li> */}

            {/* <li class={(open == 1) ? 'open' : ''}>
              <div className="icon"><StragtegyIcon /></div>
              <h4>Marketing Co-ordinator</h4>
              <span class="description">Full Time</span>
              <a onClick={() => { return doOpen(1); }} class="opener"><span>+</span><span>-</span></a>
              <div class="details">

                <strong>ABOUT THE COMPANY</strong>
                <p>PURPOSE Marketing is a digital and creative marketing agency based in Launceston CBD.</p>
                <p>We have an exciting opportunity for an enthusiastic and ambitious Marketing Co-ordinator to join our growing agency that has an established, varied portfolio of clients in Tasmania and Australia. </p>
                <p>We’re dedicated to making a real difference to business across our three core disciplines: strategy, creative and digital. As a specialist agency, our clients sit across the following categories: FMCG, Retail, F&amp;B, Luxury Brands, Travel, Events, Hospitality, &amp; B2b.</p>


                <strong>ABOUT THE ROLE</strong>
                <p>Reporting to the Head of Client Services, your primary objective will be to assist in new business efforts of the agency and assist in the day-to-day management of existing clients.</p>
                <p>Our ideal candidate will have outstanding attention to detail, a passion and strong understanding of marketing, social media and have a thirst for a career in marketing.</p>
                <p>Working in a faced paced environment, you’ll provide administration support where required, plus production of reports and generating data. Undertake research projects, co-ordinating campaigns and deliverables for new business and existing clients.</p>
                <p>With a positive, good personality, you’re highly organised with a can-do-attitude. You enjoy working under pressure and have a proven ability for meeting deadlines. Comfortable in working alone and as part of a team, you’ll be able to demonstrate your passion and commitment as you continue to hit deadlines on time and to a high quality.</p>
                <p>Working collaboratively, you’ll be supported and work alongside a passionate agency team: web developers, strategists, client service, designers, and copywriters.</p>



                <strong>EXPERIENCE</strong>
                <p>ESSENTIAL</p>
                <p>
                  <ul>
                    <li>At least 12 months experience of working in a similar role.</li>
                    <li>Proven administration experience and highly organised.</li>
                    <li>Excellent customer liaison skills, able to work across multiple clients.</li>
                    <li>Strong relationship management skills with a proven ability to deliver an exceptional customer experience.</li>
                    <li>Proficient in word, excel, ppt, and experience of using social media platforms.</li>
                    <li>High level of accuracy, with an exceptional attention to detail.</li>
                    <li>Able to work to tight deadlines with minimal supervision.</li>
                    <li>Strong time-management skills and the ability to juggle multiple priorities.</li >
                  </ul>
                </p>

                
                <strong>DESIRABLE</strong>
                <p>
                  <ul>
                    <li>Previous experience of working within a marketing team, marketing agency, or similar business.</li>
                    <li>An understanding and experience of working with Facebook Business Suite or Business Manager.</li>
                    <li>Holder of a full and clean drivers license.</li>
                  </ul>
                </p>
                


                <strong>JOIN US</strong>
                <p>This is an exciting opportunity for an ambitious, capable person that’s looking for a career in marketing. </p>

                <strong>APPLY</strong>
                <p>If you’re looking for a new challenge and believe you’re the person we’re looking for, apply today and move your career forward with PURPOSE. </p>
                <p>
                  To apply, please include all of the following:
                  <ul>
                    <li>Covering Letter, including an indication of expected salary.</li>
                    <li>Your Updated Resume.</li>
                    <li>Detail or link to your work portfolio.</li>
                  </ul>
                </p>
                <p>Closing date for applications: Sunday 3rd September 2023.</p> 

                <p>The remuneration package will be determined by experience.</p>

                <p>
                  <ul>
                    <li><strong>You must be an Australian Citizen or hold a valid visa that allows you to work in Australia to apply. </strong></li>
                    <li>All applications will be assessed as they are received.</li>
                    <li>Applications will not be accepted from overseas, recruitment agencies or outsourcing agents/businesses. </li>
                  </ul>
                </p>

                <p><strong>Please note</strong>: Only successful candidates will be contacted.</p>

              </div>
            </li> */}

            



            {/* <li class={(open == 3) ? 'open' : ''}>
              <div className="icon"><CreativeIcon /></div>
              <h4>Art Director / Senior Creative  </h4>
              <span class="description">Full Time</span>
              <a onClick={() => { return doOpen(3); }} class="opener"><span>+</span><span>-</span></a>
              <div class="details">


                <strong>ABOUT THE COMPANY</strong>
                <p>PURPOSE Marketing is a digital and creative marketing agency based in Launceston CBD.</p>
                <p>We have a wonderful opportunity for an experienced Art Director / Senior Creative to join our growing agency that has an established, varied portfolio of clients in Tasmania and Australia.</p>
                <p>We’re dedicated to making a real difference to business across our three core disciplines: strategy, creative and digital. As a specialist agency, our clients sit across the following categories: FMCG, Retail, F&amp;B, Luxury Brands, Travel, Events, Hospitality, &amp; B2b.</p>



                <strong>ABOUT THE ROLE</strong>
                <p>We’re looking for a talented and passionate person with a strong background in design and a wealth of experience of ideally working with FMCG, retail, F&B and luxury brands.</p>
                <p>With at least three years’ experience of working in a similar role, you enjoy working in a fast-paced creative environment. Knowledgeable, passionate and keen to push creative boundaries, you can motivate and lead a small, dynamic team of designers and copywriters. Creating ideas and concepts from a brief is one of your key and core strengths, as is the ability to produce high quality deliverables across multi-channels.</p>
                <p>With a good, positive personality, you’re organised with a can-do attitude, you enjoy being in the limelight as you present creative ideas to clients and internal stakeholders. Comfortable in working alone and as part of a team, no two days are likely to be the same as you’ll work on a number of projects and campaigns in any given week.</p>
                <p>This is an important hands-on role that will require you to lead by example, using your skill, experience and creativity to help shape the work of the creative team.</p>
                <p>Working collaboratively, you’ll be supported and work alongside a passionate agency team: web developers, strategists, client service, designers and copywriters.</p>






                <strong>EXPERIENCE</strong>
                <p>Essential for this role </p>
                <p>Minimum of three years’ experience of working in a senior creative role within an advertising or marketing agency / within a marketing team or creative department within a commercial business. </p>
                <p>
                  <ul>
                    <li>A solid and diverse portfolio that demonstrates your high-level creative thinking and ability to ideate and create concepts by working from a brief.</li>
                    <li>Experience of working with clients in all or some of the following categories: FMCG, retail, F&amp;B, and luxury brands.</li>
                    <li>Proven ability to conceptualise, develop and produce deliverables (physical &amp; digital) from initial ideation to final artwork / output.</li>
                    <li>Eagerness to roll up your sleeves, work on the tools and lead by example: Photoshop / Illustrator / Indesign / Adobe Suite.</li>
                    <li>Knowledge of best practices in design and consumer experience.</li>
                    <li>The ability to communicate, to lead and inspire a small team of designers / copywriters within the creative team.</li>
                    <li>Experienced in briefing and working with videographers, photographers and other content creators (internal and external).</li>
                    <li>A natural flair for ideation, including the presenting of creative concepts, work and ideas to internal stakeholders and clients.</li>
                    <li>Exhibit thought leadership through day-to-day work.</li>
                  </ul></p>


                <strong>JOIN US</strong>
                <p>This is an ideal opportunity for an existing Art Director or Senior Creative who is looking for a new challenge and is looking for career progression. </p>

                <strong>APPLY FOR THIS ROLE</strong>
                <p>If you’re looking for a new challenge and believe you’re the person we’re looking for, apply today and move your career forward with PURPOSE. </p>

                <p>To apply, please include all of the following:</p>

                <p>
                  <ul>
                    <li>Covering Letter, including an indication of expected salary.</li>
                    <li>Your Updated Resume.</li>
                    <li>Detail or link to your work portfolio.</li>
                  </ul>
                </p>
                <p>Closing date for applications: Sunday 3rd September 2023.</p>

                <p>The remuneration package will be determined by experience.</p>

                <p>
                  <ul>
                    <li><strong>You must be an Australian Citizen or hold a valid visa that allows you to work in Australia to apply. </strong></li>
                    <li>All applications will be assessed as they are received.</li>
                    <li>Applications will not be accepted from overseas, recruitment agencies or outsourcing agents/businesses. </li>
                  </ul>
                </p>

                <p><strong>Please note</strong>: Only successful candidates will be contacted.</p>

              </div>
            </li> */}


          </ul>

        </div>
      </section>
    </>
  );

}

export default CareersPage;
